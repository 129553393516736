.d-flex {
  display: flex;
}
.justify-content--center {
  justify-content: center;
}

// TODO: designに組み込む
.checkbox-input.error-boader + .checkbox-parts::before {
  border: 1px solid #ff0054 !important;
}

.radio-input.error-boader + label::before {
  border: 1px solid #ff0054 !important;
}

.file-upload-form-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 0;
  color: #000000;

  .sending-message {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 16px;
    color: #000000;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
}

.status-gray {
  color: #999999;
}

.btn:disabled {
  cursor: default;
  pointer-events:none;
  filter:alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4;
}

.uncheckable-checkbox-input:disabled + label {
  filter:alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4;
}

.checkbox-input:disabled + label {
  filter:alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4;
}

.tooltip {
  position: relative;
  overflow: visible;

  &__description-left {
    position: absolute;
    width: 300px;
    top: 50%;
    right: 25%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-size: 14px;
    color: #666;
    background: #fff;
    text-align: center;
    border-radius: 8px;
    border: 2px solid #0077b7;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s all;
    pointer-events: none;
  }

  &:hover {
    .tooltip-description {
      visibility: visible;
      opacity: 1;
    }
  }
}

.hide-with-empty-child:has(div[style='display: none;']) {
  display: none;
}

.radio-input:checked + label::after {
  top: 0;
  margin: auto;
}

.list-box-align {
  min-height: 155px;
}
