@charset "utf-8";

/*
================================================================
	 basic reset
================================================================
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,

article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
   	font-family: 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    vertical-align:baseline;
    background:transparent;
}

strong{
	font-weight:bold;
}

hr{
	display:none;
}


html {
	font-size: 62.5%;
	background: #f3f3f3;
}

body {
	font-size: 1.4em;
}


body {
    line-height:1.6;
    color:#333;
	word-wrap: break-word;
	font-family: 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	text-rendering: optimizeLegibility;
	font-feature-settings: 'kern';
	-webkit-font-feature-settings: 'kern';
	-moz-font-feature-settings: 'kern';
	-moz-font-feature-settings: 'kern=1';
	-webkit-font-smoothing: subpixel-antialiased;
	font-weight: 500;
}

button, input, select, textarea {
	font-family: 游ゴシック体, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section { 
	display:block;
}

ul,ol {
    list-style:none;
}

blockquote, q {
    quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}



del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}

table {
    border-collapse:collapse;
    border-spacing:0;
}

input, select {
    vertical-align:middle;
}

img{
	vertical-align:bottom;
}
em{font-style:normal !important;}

::selection {
  background: #56bff0;
  color:#fff;
}

table td{word-break: break-all;}


/*
================================================================
	 basic modules
================================================================
*/


/* clearfix
--------------------------------------------------------------*/
.clearfix:after{
	height: 0;
	visibility: hidden;
	content: ".";
	display: block;
	clear: both;
	line-height: 0;
}
.clearfix{
	_height: 1px;
	min-height: 1px;
	/*\*//*/
	height: auto;
	overflow: hidden;
	/**/
}

.clear{
clear:both;
}


/* margin padding
--------------------------------------------------------------*/

.mt0 { margin-top:0px !important; }
.mt5 { margin-top:5px !important; }
.mt10 { margin-top:10px !important; }
.mt15 { margin-top:15px !important; }
.mt20 { margin-top:20px !important; }
.mt25 { margin-top:25px !important; }
.mt30 { margin-top:30px !important; }
.mt35 { margin-top:35px !important; }
.mt40 { margin-top:40px !important; }
.mt50 { margin-top:50px !important; }
.mt60 { margin-top:60px !important; }

.mb0 { margin-bottom:0px !important; }
.mb5 { margin-bottom:5px !important; }
.mb10 { margin-bottom:10px !important; }
.mb15 { margin-bottom:15px !important; }
.mb20 { margin-bottom:20px !important; }
.mb25 { margin-bottom:25px !important; }
.mb30 { margin-bottom:30px !important; }
.mb35 { margin-bottom:35px !important; }
.mb40 { margin-bottom:40px !important; }
.mb50 { margin-bottom:50px !important; }
.mb60 { margin-bottom:60px !important; }
.mb130 { margin-bottom:130px !important; }

.ml0 { margin-left:0px !important; }
.ml5 { margin-left:5px !important; }
.ml10 { margin-left:10px !important; }
.ml15 { margin-left:15px !important; }
.ml20 { margin-left:20px !important; }
.ml25 { margin-left:25px !important; }
.ml30 { margin-left:30px !important; }
.ml35 { margin-left:35px !important; }
.ml40 { margin-left:40px !important; }
.ml50 { margin-left:50px !important; }
.ml60 { margin-left:60px !important; }

.mr0 { margin-right:0px !important; }
.mr5 { margin-right:5px !important; }
.mr10 { margin-right:10px !important; }
.mr15 { margin-right:15px !important; }
.mr20 { margin-right:20px !important; }
.mr25 { margin-right:25px !important; }
.mr30 { margin-right:30px !important; }
.mr35 { margin-right:35px !important; }
.mr40 { margin-right:40px !important; }
.mr50 { margin-right:50px !important; }
.mr60 { margin-right:60px !important; }

.pt0 { padding-top:0px !important; }
.pt5 { padding-top:5px !important; }
.pt10 { padding-top:10px !important; }
.pt15 { padding-top:15px !important; }
.pt20 { padding-top:20px !important; }
.pt25 { padding-top:25px !important; }
.pt30 { padding-top:30px !important; }
.pt35 { padding-top:35px !important; }
.pt40 { padding-top:40px !important; }
.pt50 { padding-top:50px !important; }
.pt60 { padding-top:60px !important; }

.pb0 { padding-bottom:0px !important; }
.pb5 { padding-bottom:5px !important; }
.pb10 { padding-bottom:10px !important; }
.pb15 { padding-bottom:15px !important; }
.pb20 { padding-bottom:20px !important; }
.pb25 { padding-bottom:25px !important; }
.pb30 { padding-bottom:30px !important; }
.pb35 { padding-bottom:35px !important; }
.pb40 { padding-bottom:40px !important; }
.pb50 { padding-bottom:50px !important; }
.pb60 { padding-bottom:60px !important; }

.pl0 { padding-left:0px !important; }
.pl5 { padding-left:5px !important; }
.pl10 { padding-left:10px !important; }
.pl15 { padding-left:15px !important; }
.pl20 { padding-left:20px !important; }
.pl25 { padding-left:25px !important; }
.pl30 { padding-left:30px !important; }
.pl35 { padding-left:35px !important; }
.pl40 { padding-left:40px !important; }
.pl50 { padding-left:50px !important; }
.pl60 { padding-left:60px !important; }

.pr0 { padding-right:0px !important; }
.pr5 { padding-right:5px !important; }
.pr10 { padding-right:10px !important; }
.pr15 { padding-right:15px !important; }
.pr20 { padding-right:20px !important; }
.pr25 { padding-right:25px !important; }
.pr30 { padding-right:30px !important; }
.pr35 { padding-right:35px !important; }
.pr40 { padding-right:40px !important; }
.pr50 { padding-right:50px !important; }
.pr60 { padding-right:60px !important; }

/* TEXT-ALIGN,VERTICAL-ALIGN */
.taL { text-align:left !important; }
.taR { text-align:right !important; }
.taC { text-align:center !important; }

.vaT { vertical-align:top !important; }
.vaM { vertical-align:middle !important; }
.vaB { vertical-align:bottom !important; }


/* FONT-WEIGHT */
.fb { font-weight:bold !important; }
.fl { font-weight:normal !important; }

/* FONT-COLOR */
.fcAtt{color:#ff4040 !important;font-style:none;}
.fcMbb{color:#0077b7 !important;font-style:none;}

/* FONT-SIZE */
.fsS{font-size:1.2rem !important;}
.fsM{font-size:1.4rem !important;}
.fsL{font-size:1.8rem !important;}
.fsLL{font-size:2.2rem !important;}
/* BREAK */
.noBr{white-space:nowrap;}
.allBr{word-break:break-all !important;word-wrap: break-word !important;}

/* FLOAT */
.floR { float:right; }
.floL { float:left; }

/* DISPLAY */
.dispNone{
	display:none;
}
.dispBlock{
	display:block;
}
.dispInline{
	display:inline;
}
.dispInlineB{
	display:inline-block;
}
