@charset "UTF-8";
/*CSS INFOMATION=======================

LAYOUT CSS

File name : layout.css

====================================*/
/*

common
-----------------------------------*/
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
  color: #0077b7;
  text-decoration: none;
  border-style: none;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  a:active, a:hover {
    text-decoration: none;
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    opacity: 0.5;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }
  a[href^="tel:"] {
    pointer-events: none; }

@media screen and (min-width: 769px) {
  .spBox {
    display: none; } }

@media screen and (min-width: 1280px) {
  .padBox {
    display: none; } }

@media screen and (max-width: 1280px) {
  .pad-ml + label {
    margin-left: 20px !important; } }

@media screen and (max-width: 768px) {
  .pcBox {
    display: none; }
  .padBox {
    display: none; }
  .pad-ml + label {
    margin-left: 0 !important; } }

.contents {
  min-height: 780px; }

#clear {
  display: none; }

/*

header
-----------------------------------*/
header {
  padding: 10px 0;
  height: 70px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  z-index: 999; }
  header .jidweb-header {
    position: fixed;
    width: 100%;
    border-top: 5px solid #0077b7;
    top: 0;
    z-index: 999; }
    header .jidweb-header .btn-jid a {
      background: #0077b7;
      padding: 5px 10px;
      color: #fff;
      text-align: center;
      font-size: 12px;
      position: absolute;
      top: 0;
      right: 175px;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px; }
    header .jidweb-header .btn-hm a {
      background: #ea0000;
      padding: 5px 10px;
      color: #fff;
      text-align: center;
      font-size: 12px;
      position: absolute;
      top: 0;
      right: 40px;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px; }
  header .hm-header {
    position: fixed;
    width: 100%;
    border-top: 5px solid #ea0000;
    top: 0;
    z-index: 999; }
    header .hm-header .btn-jid a {
      background: #0077b7;
      padding: 5px 10px;
      color: #fff;
      text-align: center;
      font-size: 12px;
      position: absolute;
      top: 0;
      right: 175px;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px; }
    header .hm-header .btn-hm a {
      background: #ea0000;
      padding: 5px 10px;
      color: #fff;
      text-align: center;
      font-size: 12px;
      position: absolute;
      top: 0;
      right: 40px;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px; }
  header h1 a {
    color: #0077b7;
    display: table-cell;
    display: -webkit-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 42px;
    left: 20px; }
  header .header-name {
    position: absolute;
    top: 60px;
    right: 200px;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: right; }
  header .header-txt {
    font-size: 10px;
    position: absolute;
    top: 30px;
    right: 20px;
    padding: 0 0 0 0;
    text-align: right; }

/*

sidebar
-----------------------------------*/
.sidebar {
  width: 230px;
  padding: 55px 0 50px;
  position: absolute;
  margin: 20px 0 0;
  top: 56;
  background-color: #0077b7;
  border-top-right-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  -moz-border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  -webkit-border-bottom-right-radius: 15px;
  -moz-border-bottom-right-radius: 15px;
  z-index: 9998;
  overflow: hidden; }

.fixed {
  width: 230px;
  padding: 55px 0 50px;
  margin: 20px 0 0;
  position: fixed !important;
  top: 0;
  z-index: 999; }

.sidebar a {
  height: 25px;
  color: #fff; }
  .sidebar a:hover {
    text-decoration: none;
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1; }

.sidebar nav {
  width: 230px; }
  .sidebar nav li {
    /*&.nav08{
		  display: none;
	    }*/ }
    .sidebar nav li a {
      padding: 12px 0 12px 55px;
      display: block;
      font-weight: bold; }
      .sidebar nav li a span {
        font-size: 1.4rem;
        line-height: 1.9; }
    .sidebar nav li.nav00 a {
      background: url("../img/nav_icon00.svg") center left 20px no-repeat;
      background-size: 20px auto; }
      .sidebar nav li.nav00 a:hover {
        background: #fff url("../img/nav_icon00_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
      .sidebar nav li.nav00 a.current {
        background: #fff url("../img/nav_icon00_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
    .sidebar nav li.nav01 a {
      background: url("../img/nav_icon01.svg") center left 20px no-repeat;
      background-size: 20px auto; }
      .sidebar nav li.nav01 a:hover {
        background: #fff url("../img/nav_icon01_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
      .sidebar nav li.nav01 a.current {
        background: #fff url("../img/nav_icon01_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
    .sidebar nav li.nav02 a {
      background: url("../img/nav_icon02.svg") center left 20px no-repeat;
      background-size: 19px auto; }
      .sidebar nav li.nav02 a:hover {
        background: #fff url("../img/nav_icon02_on.svg") center left 20px no-repeat;
        background-size: 19px auto;
        color: #0077b7; }
      .sidebar nav li.nav02 a.current {
        background: #fff url("../img/nav_icon02_on.svg") center left 20px no-repeat;
        background-size: 19px auto;
        color: #0077b7; }
    .sidebar nav li.nav03 a {
      background: url("../img/nav_icon03.svg") center left 20px no-repeat;
      background-size: 20px 20px; }
      .sidebar nav li.nav03 a:hover {
        background: #fff url("../img/nav_icon03_on.svg") center left 20px no-repeat;
        background-size: 20px 20px;
        color: #0077b7; }
      .sidebar nav li.nav03 a.current {
        background: #fff url("../img/nav_icon03_on.svg") center left 20px no-repeat;
        background-size: 20px 20px;
        color: #0077b7; }
    .sidebar nav li.nav04 a {
      background: url("../img/nav_icon10.svg") center left 20px no-repeat;
      background-size: 20px auto; }
      .sidebar nav li.nav04 a:hover {
        background: #fff url("../img/nav_icon10_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
      .sidebar nav li.nav04 a.current {
        background: #fff url("../img/nav_icon10_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
    .sidebar nav li.nav05 a {
      background: url("../img/nav_icon05.svg") center left 20px no-repeat;
      background-size: 20px auto; }
      .sidebar nav li.nav05 a:hover {
        background: #fff url("../img/nav_icon05_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
      .sidebar nav li.nav05 a.current {
        background: #fff url("../img/nav_icon05_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
    .sidebar nav li.nav06 a {
      background: url("../img/nav_icon06.svg") center left 20px no-repeat;
      background-size: 20px auto; }
      .sidebar nav li.nav06 a:hover {
        background: #fff url("../img/nav_icon06_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
      .sidebar nav li.nav06 a.current {
        background: #fff url("../img/nav_icon06_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
    .sidebar nav li.nav07 a {
      background: url("../img/nav_icon07.svg") center left 20px no-repeat;
      background-size: 20px auto; }
      .sidebar nav li.nav07 a:hover {
        background: #fff url("../img/nav_icon07_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
      .sidebar nav li.nav07 a.current {
        background: #fff url("../img/nav_icon07_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
    .sidebar nav li.nav08 a {
      background: url("../img/nav_icon08.svg") center left 20px no-repeat;
      background-size: 20px auto; }
      .sidebar nav li.nav08 a:hover {
        background: #fff url("../img/nav_icon08_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
      .sidebar nav li.nav08 a.current {
        background: #fff url("../img/nav_icon11_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
    .sidebar nav li.nav09 a {
      background: url("../img/nav_icon09.svg") center left 20px no-repeat;
      background-size: 20px auto; }
      .sidebar nav li.nav09 a:hover {
        background: #fff url("../img/nav_icon09_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
      .sidebar nav li.nav09 a.current {
        background: #fff url("../img/nav_icon09_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
    .sidebar nav li.nav10 a {
      background: url("../img/nav_icon11.svg") center left 20px no-repeat;
      background-size: 20px auto; }
      .sidebar nav li.nav10 a:hover {
        background: #fff url("../img/nav_icon11_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }
      .sidebar nav li.nav10 a.current {
        background: #fff url("../img/nav_icon11_on.svg") center left 20px no-repeat;
        background-size: 20px auto;
        color: #0077b7; }

#menuButton {
  display: block;
  width: 100%;
  height: 40px;
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 9995;
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  opacity: 0.5; }
  #menuButton span {
    display: block;
    background: #fff;
    width: 22px;
    height: 2px;
    position: absolute;
    right: 18px;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s; }
    #menuButton span:first-child {
      top: 11px; }
    #menuButton span:nth-child(2) {
      margin-top: -1px;
      top: 50%; }
    #menuButton span:last-child {
      bottom: 11px; }
  #menuButton:hover span:first-child {
    width: 13px;
    -webkit-transform: translateX(-10px) translateY(13px) rotate(45deg);
    -moz-transform: translateX(-10px) translateY(13px) rotate(45deg);
    -ms-transform: translateX(-10px) translateY(13px) rotate(45deg);
    transform: translateX(-10px) translateY(13px) rotate(45deg); }
  #menuButton:hover span:last-child {
    width: 13px;
    -webkit-transform: translateX(-10px) translateY(-13px) rotate(-45deg);
    -moz-transform: translateX(-10px) translateY(-13px) rotate(-45deg);
    -ms-transform: translateX(-10px) translateY(-13px) rotate(-45deg);
    transform: translateX(-10px) translateY(-13px) rotate(-45deg); }

a#menuButton:hover {
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1; }

a.active:hover span:first-child {
  width: 13px !important;
  -webkit-transform: translateX(1px) translateY(13px) rotate(-45deg) !important;
  -moz-transform: translateX(1px) translateY(13px) rotate(-45deg) !important;
  -ms-transform: translateX(1px) translateY(13px) rotate(-45deg) !important;
  transform: translateX(1px) translateY(13px) rotate(-45deg) !important; }

a.active:hover span:last-child {
  width: 13px;
  -webkit-transform: translateX(1px) translateY(-13px) rotate(45deg) !important;
  -moz-transform: translateX(1px) translateY(-13px) rotate(45deg) !important;
  -ms-transform: translateX(1px) translateY(-13px) rotate(45deg) !important;
  transform: translateX(1px) translateY(-13px) rotate(45deg) !important; }

.siten-box {
  color: #fff;
  margin: 30px 20px 0;
  width: 190px; }
  .siten-box .siten-ttl {
    position: relative;
    padding: 0 0 10px;
    font-size: 1rem;
    width: 100% !important; }

.siten-ttl span {
  position: relative;
  z-index: 4;
  display: inline-block;
  margin: 0 0;
  padding: 10px 10px 0 0;
  background-color: #0077b7;
  text-align: left;
  font-size: 1rem; }

.siten-ttl::before {
  position: absolute;
  top: 50%;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
  height: 1px;
  background-color: #6892d5; }

.siten-box .siten-ttl02 {
  padding: 0 0 5px; }

.siten-box p {
  font-size: 1.1rem; }

/*

step-nav
-----------------------------------*/
.fixed02 {
  position: fixed !important;
  top: 20px; }

.step-box {
  margin: 0;
  padding: 0; }
  .step-box .step-nav ol {
    margin: 0 0 30px;
    display: table;
    table-layout: fixed;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 60px;
    border-left: solid 1px #ddd;
    border-right: solid 1px #ddd;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    overflow: hidden;
    border-top: solid 1px #ddd;
    border-bottom: solid 1px #ddd; }
    .step-box .step-nav ol li {
      position: relative;
      display: table-cell;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding-left: 25px;
      text-align: center;
      vertical-align: middle;
      color: #0077b7;
      background-color: #fff;
      font-weight: bold;
      font-size: 1.3rem; }
  .step-box .step-box .step-nav ol li span {
    font-size: 1.3rem; }
  .step-box .step-nav ol li:first-child {
    padding-left: 0; }
  .step-box .step-nav ol li.current {
    background-color: #0077b7;
    color: #fff; }
    .step-box .step-nav ol li.current a {
      color: #fff; }
  .step-box .step-nav ol li:before {
    content: "";
    position: absolute;
    top: -1px;
    right: -31px;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 30px solid #ddd;
    z-index: 99; }
  .step-box .step-nav ol li:nth-child(5):before {
    display: none; }
  .step-box .step-nav ol li:after {
    content: "";
    position: absolute;
    top: -1px;
    right: -30px;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 30px solid #fff;
    z-index: 99; }
  .step-box .step-nav ol li:last-child:after {
    display: none; }
  .step-box .step-nav ol li.current {
    border-top: solid 1px #0077b7;
    border-bottom: solid 1px #0077b7; }
    .step-box .step-nav ol li.current:after {
      border-left: 30px solid #0077b7; }

@media screen and (max-width: 1080px) {
  .step-box .step-nav ol li {
    font-size: 1.1rem;
    line-height: 1.2; } }

@media screen and (max-width: 1080px) {
  .step-box .step-nav ol li span {
    font-size: 1.1rem;
    line-height: 1.2; } }

.step-box02 {
  z-index: 21;
  margin: 0 30px 0 0;
  width: 180px;
  position: absolute;
  bottom: 110px;
  right: -15px; }
  .step-box02 .step-box-close {
    position: absolute;
    top: -9px;
    right: -9px;
    z-index: 9;
    cursor: pointer; }
  .step-box02 .step-box-open {
    background: #0077b7;
    color: #fff;
    padding: 10px 5px 10px 5px;
    width: 20px;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: -30px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    -webkit-border-top-left-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-topleft: 6px;
    -moz-border-radius-bottomleft: 6px; }
  .step-box02 .step-nav {
    position: relative; }
  .step-box02 .step-nav ol {
    border: 1px solid #0077b7;
    background-color: #fff;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    overflow: hidden; }
    .step-box02 .step-nav ol li {
      position: relative;
      border-bottom: solid 1px #0077b7;
      color: #0077b7;
      font-weight: bold;
      font-size: 1.2rem; }
      .step-box02 .step-nav ol li:last-child {
        border: none; }
      .step-box02 .step-nav ol li a {
        background: #fff;
        display: block;
        padding: 3px 10px;
        color: #0077b7; }
        .step-box02 .step-nav ol li a.current {
          background: #0077b7;
          display: block;
          padding: 3px 10px;
          color: #fff;
          pointer-events: none; }

@media screen and (max-width: 1080px) {
  .step-box02 .step-nav ol li span {
    font-size: 1.9rem; } }

.m-ttl {
  margin: 110px 0 30px !important; }

/*

main
-----------------------------------*/
.main {
  padding: 20px 40px 80px;
  margin: 0 0 0 230px;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .main h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #0077b7;
    margin: 0 0 30px;
    padding: 0 0 20px;
    border-bottom: 3px solid #ddd; }
    .main h2 span.ttl-icon-a {
      background: #f29a00;
      color: #fff;
      padding: 2px 8px;
      font-size: 1.8rem;
      margin: 0 0 0 15px; }
    .main h2 span.ttl-icon-b {
      background: #00b254;
      color: #fff;
      padding: 2px 8px;
      font-size: 1.8rem;
      margin: 0 0 0 15px; }
    .main h2 span.ttl-icon-c {
      background: #999;
      color: #fff;
      padding: 2px 8px;
      font-size: 1.8rem;
      margin: 0 0 0 15px; }
    .main h2 span.ttl-icon-d {
      background: #ff0000;
      color: #fff;
      padding: 2px 8px;
      font-size: 1.8rem;
      margin: 0 0 0 15px; }

/*

footer
-----------------------------------*/
footer {
  text-align: left;
  background-color: #fff;
  font-size: 1.1rem;
  padding: 40px 30px 80px;
  position: relative;
  z-index: 9800; }
  footer .foot-contact {
    position: absolute;
    top: 20px;
    right: 15px;
    z-index: 9820; }
  footer nav {
    overflow: hidden;
    margin: 0 0 10px; }
    footer nav li {
      float: left;
      margin: 0 15px 0 0; }
      footer nav li a {
        font-size: 1.2rem; }

.logo-jid {
  position: absolute;
  right: 40px;
  top: 30px; }

.btn-footerArea {
  width: 100%;
  position: -webkit-sticky;
  /* safari対応 */
  position: sticky;
  bottom: 0;
  padding: 25px 0;
  background: #ddd;
  border-top: 1px solid #ddd; }
  .btn-footerArea .inner {
    width: 100%;
    text-align: center; }
    .btn-footerArea .inner button {
      display: inline-block;
      margin: 0 5px 0; }
  .btn-footerArea .innerB {
    width: 100%;
    text-align: center; }
    .btn-footerArea .innerB button,
    .btn-footerArea .innerB a {
      display: inline-block;
      margin: 0 5px 10px; }
  .btn-footerArea .info {
    padding: 0 0 15px;
    text-align: center; }
    .btn-footerArea .info .info-s {
      padding: 0 10px;
      font-weight: bold;
      font-size: 1.2rem; }
      @media all and (min-width: 0px) and (max-width: 768px) {
        .btn-footerArea .info .info-s {
          font-size: 1rem; } }
  .btn-footerArea .lock {
    text-align: center;
    padding: 0 10px;
    font-size: 1.2rem;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    @media all and (min-width: 0px) and (max-width: 768px) {
      .btn-footerArea .lock {
        font-size: 1.1rem; } }
  .btn-footerArea legend {
    text-align: center; }
  .btn-footerArea fieldset {
    margin: 0 auto 10px;
    max-width: 680px;
    background: #eee;
    padding: 15px 20px 20px;
    border: 1px solid #bbb;
    border-radius: 10px; }
    @media all and (min-width: 0px) and (max-width: 760px) {
      .btn-footerArea fieldset {
        padding: 15px 20px 10px;
        margin: 0 20px 10px; } }

.btn-footerAreaB {
  width: 100%;
  position: -webkit-sticky;
  /* safari対応 */
  position: sticky;
  bottom: 0;
  padding: 0 0;
  background: #ddd;
  z-index: 100; }
  .btn-footerAreaB .step-box02 {
    z-index: 21;
    margin: 0 30px 0 0;
    width: 180px;
    position: absolute;
    bottom: 90px;
    right: -15px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px; }

.soushin {
  display: none; }

.breadcrumbs {
  padding: 0 0 20px 30px; }
  .breadcrumbs li {
    display: inline-block;
    margin-left: 12px;
    padding-left: 10px;
    position: relative; }
    .breadcrumbs li a {
      font-size: 1.2rem; }
  .breadcrumbs li:first-child {
    margin-left: 0;
    padding-left: 0;
    background: none; }
  .breadcrumbs li:after {
    content: ">";
    font-size: 1rem;
    position: absolute;
    top: 4px;
    right: -14px; }
  .breadcrumbs li:last-child:after {
    content: none; }

#topBtn {
  position: fixed;
  bottom: 20px;
  right: 15px;
  display: block;
  color: #595532;
  text-decoration: none;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  line-height: 22px;
  padding: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 9999;
  cursor: pointer;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

#topBtn:hover {
  opacity: 0.5;
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  opacity: 0.5;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

/*

box
-----------------------------------*/
.box-primary {
  margin: 0 0 40px;
  clear: both;
  position: relative;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  overflow: hidden; }

/* box form内グレー　*/
.box-secondary {
  margin: 0 0 1%;
  padding: 15px;
  clear: both; }

/* box table用　*/
.box-third {
  margin: 0 0 1%;
  padding: 20px;
  clear: both;
  position: relative; }
  .box-third .btn-close-nyukyo {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer; }
    .box-third .btn-close-nyukyo img {
      width: 30px;
      height: auto; }

/* box table用　*/
.box-fourth {
  clear: both;
  margin: 0 0 10px; }

.box-six {
  margin: 0 0 40px;
  clear: both;
  position: relative;
  border: 5px solid #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  overflow: hidden; }

.box-m {
  margin: 20px 0;
  clear: both; }
  .box-m .col2 .left {
    width: 47% !important;
    float: left;
    border: none; }
  .box-m .col2 .right {
    width: 51% !important;
    float: right;
    border: none; }

.nks-box {
  padding: 30px 30px 30px;
  margin: 0 0 30px;
  background: #eee !important; }
  .nks-box h4 {
    background: #ddd !important; }
  .nks-box .form-listbox {
    border-bottom: 1px solid #ddd; }
  .nks-box .col2 {
    border-bottom: 1px solid #ddd; }

.box-boader {
  border: 1px solid #ddd !important; }

/* box 枠なし　*/
.box-fifth {
  position: relative; }

.box-inner {
  padding: 30px 30px 40px;
  overflow: hidden; }
  .box-inner .thanks {
    text-align: center;
    padding: 30px 0; }
    .box-inner .thanks .txtA {
      font-size: 1.8rem;
      font-weight: bold;
      padding: 0 0 10px; }
    .box-inner .thanks .txtB {
      padding: 0 0 20px; }

.box-innerB {
  padding: 10px 15px; }

.box-quaternary .box-inner {
  padding: 0px 0px 50px; }

.accident-mailbox {
  padding: 0 0 10px;
  overflow: hidden; }
  .accident-mailbox #accident-mail-content,
  .accident-mailbox #accident-mail-content {
    overflow-y: hidden; }

.change-radiobox {
  margin: 0 0 30px; }

.txt-blue {
  color: #0077b7; }

/* box color　*/
.box-white {
  background: #fff; }

.box-blue {
  background: #daebf7; }

.box-grey {
  background: #f7f7f7; }

.box-yellow {
  background: #fffee3; }

.box-boader {
  border: 3px solid #ddd; }

/* box img */
.box-img {
  text-align: center;
  margin: 20px 0 0; }
  .box-img img {
    border: 1px solid #ddd; }

/* box ttl　*/
.box-primary h3 {
  background: #d6e8f5;
  padding: 17px 20px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #0077b7; }
  .box-primary h3.ttl-open {
    background: #d6e8f5 url("../img/icon_close04.svg") center right 10px no-repeat;
    background-size: 35px auto;
    cursor: pointer; }
  .box-primary h3.active {
    background: #d6e8f5 url("../img/icon_open04.svg") center right 10px no-repeat;
    background-size: 35px auto;
    cursor: pointer; }
  .box-primary h3 span {
    font-size: 1.2rem; }
  .box-primary h3 span.icon-ttlA {
    color: #fff;
    padding: 4px 10px;
    margin: 0 15px 0 0;
    font-size: 1.2rem;
    background: #eeae00; }
  .box-primary h3 span.icon-ttlB {
    color: #fff;
    padding: 4px 10px;
    margin: 0 15px 0 0;
    font-size: 1.2rem;
    background: #00b254; }
  .box-primary h3 .btn-kakunin {
    border: 0;
    background: #ff0054;
    color: #fff !important;
    padding: 2px 3px;
    display: block;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    width: 90px;
    font-size: 1.1rem;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    margin: -10px 0 1px;
    cursor: pointer;
    position: absolute;
    top: 32px;
    right: 60px;
    z-index: 99; }
  .box-primary h3 .label-kakunin {
    background: #00b254;
    color: #fff;
    padding: 2px 3px;
    text-align: center;
    font-weight: bold;
    text-align: center;
    width: 90px;
    font-size: 1.1rem;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    margin: -10px 0 1px;
    position: absolute;
    top: 32px;
    right: 60px;
    z-index: 99; }

.box-primary h4 {
  padding: 13px 0 13px 15px;
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0 0 15px;
  background: #eee; }
  .box-primary h4.ttl-close {
    background: #eee url("../img/icon_open02.svg") center right 6px no-repeat;
    background-size: 30px auto;
    cursor: pointer; }
  .box-primary h4.ttl-sub {
    background: none;
    margin: 0 0 30px;
    clear: both;
    border-bottom: 5px solid #ddd;
    padding-bottom: 10px;
    padding-left: 0;
    font-size: 1.6rem; }
  .box-primary h4.active {
    background: #eee url("../img/icon_close02.svg") center right 6px no-repeat;
    background-size: 30px auto;
    cursor: pointer;
    margin: 0; }
  .box-primary h4 .btn-small-blue {
    position: absolute;
    right: 15px;
    top: 11px; }

.box-primary div.ttl-close {
  background: #eee url("../img/icon_open02.svg") center right 6px no-repeat;
  background-size: 30px auto;
  cursor: pointer;
  padding: 11px 0 11px 13px; }

.box-primary div.active {
  background: #eee url("../img/icon_close02.svg") center right 6px no-repeat;
  background-size: 30px auto;
  cursor: pointer;
  margin: 0; }

.box-primary .none {
  display: none; }

.box-primary h5 {
  padding: 20px 0 10px;
  font-weight: bold;
  font-size: 1.4rem; }
  .box-primary h5 span {
    font-size: 2rem; }
  .box-primary h5.ttl-openB {
    text-align: center !important;
    font-size: 1.8rem; }

.txt-age {
  font-size: 2rem;
  font-weight: bold; }

.toast {
  padding: 20px;
  margin: 0 0 30px;
  position: relative;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  overflow: hidden; }
  .toast .close {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer; }

.kensaku-box {
  padding: 20px !important;
  border: 3px solid #ddd;
  background: #eee; }
  .kensaku-box .col2 {
    border-bottom: 1px solid #ddd; }
  .kensaku-box .form-listbox {
    padding: 15px !important; }

.box-open {
  margin: 0;
  padding: 0; }

.icon-genpon {
  background: #0077b7;
  padding: 5px 10px;
  color: #fff;
  width: 100px;
  text-align: center;
  font-weight: bold;
  margin: 0 10px 0 0; }

.icon-genpon-michaku {
  background: #ff0054;
  padding: 5px 10px;
  color: #fff;
  width: 100px;
  text-align: center;
  font-weight: bold;
  margin: 0 10px 0 0; }

.keiyaku-leftBox {
  width: 47%;
  float: left;
  position: relative;
  /*border: 1px solid #ddd;
	padding: 30px 30px 40px;
	box-sizing: border-box;*/ }
  .keiyaku-leftBox .form-contents {
    padding: 0;
    word-break: break-all; }
  .keiyaku-leftBox .form-ttl {
    height: 31.5px; }

.keiyaku-leftBox:after {
  content: url("../img/icon_arrow.svg");
  display: inline-block;
  position: absolute;
  top: 15px;
  right: -8%;
  width: 15px;
  height: 15px; }

.keiyaku-rightBox {
  width: 47%;
  float: right;
  /*border: 1px solid #ddd;
	padding: 30px 30px 40px;
	box-sizing: border-box;*/ }
  .keiyaku-rightBox .form-ttl {
    height: 31.5px; }
  .keiyaku-rightBox .col2 {
    overflow: hidden;
    border-bottom: none; }
    .keiyaku-rightBox .col2 .left,
    .keiyaku-rightBox .col2 .right {
      width: 100% !important;
      border-bottom: 1px solid #eee; }

/*

suggest ui
-----------------------------------*/
.ui-menu .ui-menu-item-wrapper {
  position: relative;
  padding: 3px 1em 3px 0.4em; }

.ui-widget-content {
  border: 1px solid #ddd !important;
  background: #fff !important;
  color: #0077b7 !important; }

.ui-widget-content li {
  overflow: hidden; }

/* リスト hover 時のカラー */
.ui-state-active {
  border: 1px solid #fff !important;
  background: #fff !important;
  font-weight: bold !important;
  color: #ff5092 !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.ui-autocomplete {
  max-height: 200px;
  overflow-y: auto;
  /* prevent horizontal scrollbar */
  overflow-x: hidden; }

/* IE 6 doesn't support max-height
 * we use height instead, but this forces the menu to always be this tall
 */
* html .ui-autocomplete {
  height: 100px; }

.history_label {
  float: left; }

.history_btn {
  float: right; }

/*

tab
-----------------------------------*/
.tab_area {
  overflow: hidden;
  display: table;
  margin: 15px 0 0px 20px;
  border-spacing: 10px 0; }
  .tab_area li {
    width: 160px;
    margin: 0 10px 0 0;
    padding: 10px 10px;
    cursor: pointer;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #000;
    background: #ccc;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px; }

.all_area {
  overflow: hidden;
  margin: 20px auto 0; }
  .all_area .content_area {
    padding: 15px 30px 40px;
    background: #fffee3; }
    .all_area .content_area .tab-txtbox {
      text-align: center;
      padding: 50px 0 30px;
      font-weight: bold;
      font-size: 1.8rem; }
    .all_area .content_area .tab-txtboxB,
    .all_area .content_area .tab-txtboxC {
      text-align: center;
      padding: 30px 0 0px;
      font-weight: bold;
      font-size: 1.8rem; }

.tab_area li.select {
  /*アクティブタブの装飾*/
  color: #fff;
  background: #0077b7; }

.hide {
  display: none; }

.koushinji-box,
.koushinji-boxB {
  display: none; }

.koushinji-box .col2 {
  border-bottom: 1px solid #ddd; }

.koushin-check .checkbox-parts::before {
  background: #fff !important; }

/*

login
-----------------------------------*/
.login-box {
  max-width: 460px;
  margin: 90px auto 100px; }
  .login-box h2 {
    text-align: center;
    font-size: 2.4rem;
    padding: 0 0 20px;
    color: #0077b7; }

.login-listbox {
  padding: 0 0 20px; }

.login-ttl {
  font-weight: bold;
  padding: 0 0 5px; }

.login-box .login-txt {
  margin: -20px 0 40px; }

.ttl-txt {
  text-align: center;
  padding: 0 0 30px; }

.login-ninsyou-box {
  width: 300px;
  margin: 0 auto; }
  .login-ninsyou-box input::-webkit-input-placeholder {
    font-size: 1.8rem; }
  .login-ninsyou-box input:-moz-placeholder {
    font-size: 1.8rem; }
  .login-ninsyou-box input::-moz-placeholder {
    font-size: 1.8rem; }
  .login-ninsyou-box input:-ms-input-placeholder {
    font-size: 1.8rem; }

#login-ninshou {
  font-size: 3.4rem !important;
  text-align: center; }

/*

application
-----------------------------------*/
.application-box {
  max-width: 900px;
  margin: 80px auto 100px; }
  .application-box h2 {
    text-align: center;
    font-size: 2.4rem;
    padding: 0 0 20px;
    color: #0077b7; }

.kiyaku-box {
  background: #fff;
  padding: 0 20px 20px;
  overflow-y: auto;
  height: 300px;
  margin: 0 0 20px;
  background: #fffee3; }

.kiyaku-rikai {
  text-align: center; }

/*

kakunin
-----------------------------------*/
.kakunin-box {
  max-width: 900px;
  margin: 50px auto 100px; }
  .kakunin-box h2 {
    text-align: center;
    font-size: 2.4rem;
    padding: 0 0 20px;
    color: #0077b7; }
  .kakunin-box .keiyaku-cbox {
    max-width: 730px;
    margin: 0 auto;
    padding: 30px 0; }
    .kakunin-box .keiyaku-cbox .innerA {
      border-bottom: 1px solid #ddd;
      padding: 0 0 20px;
      margin: 0 0 20px; }
    .kakunin-box .keiyaku-cbox .innerB {
      padding: 0 0 0px;
      text-align: center; }
    .kakunin-box .keiyaku-cbox .cbox {
      font-size: 1.8rem !important;
      font-weight: bold;
      padding: 5px 0;
      line-height: 1.8; }

.hokan-cbox {
  width: 100%;
  padding: 30px 0 0px; }
  .hokan-cbox .innerA {
    margin: 0 0 20px; }
  .hokan-cbox .cbox {
    font-size: 1.8rem !important;
    font-weight: bold;
    padding: 5px 0;
    line-height: 1.8;
    text-align: center; }
  .hokan-cbox #txtbox-hokan {
    width: 160px;
    font-size: 2rem;
    margin: 0 auto !important;
    text-align: center; }

/*

news list
-----------------------------------*/
dl.news-list {
  margin: 0 0 -15px;
  overflow: hidden; }
  dl.news-list dt {
    float: left;
    padding: 0 0 15px; }
  dl.news-list dd {
    margin-left: 100px;
    padding: 0 0 15px; }

.news-open {
  display: none; }

.department-news-open {
  display: none; }

/*

guide list
-----------------------------------*/
h5.ttl-guide {
  border-bottom: 2px solid #ddd;
  padding: 15px 0 10px 5px;
  margin: 0 0 15px;
  font-size: 1.5rem; }

.guide-list {
  overflow: hidden;
  margin: 20px 0 40px 10px; }
  .guide-list li {
    width: 30%;
    float: left;
    padding: 0 0 30px 5px;
    margin: 0 0 0 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 40px;
    list-style: disc; }
  .guide-list li:nth-child(4) {
    width: 30%;
    float: left;
    padding: 0 0 30px 5px;
    margin: 0 0 0 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100px !important;
    list-style: disc; }

.guide-listB {
  overflow: hidden;
  margin: 20px 0 20px 5px; }
  .guide-listB li {
    width: 30%;
    float: left;
    padding: 0 0 30px 10px;
    margin: 0 0 0 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 80px;
    list-style: disc; }

/*

table list
-----------------------------------*/
table.listA td {
  padding: 20px 10px;
  text-align: center; }

table.listA thead {
  border-bottom: 1px solid #eee; }
  table.listA thead td {
    color: #006da8;
    font-weight: bold;
    font-size: 1.2rem; }

table.listA tbody tr:last-child td {
  border-bottom: none; }

table.listB td {
  width: 48%;
  padding: 1%; }

table.listC td {
  padding: 20px 10px;
  text-align: center; }

table.listC thead {
  border-bottom: 1px solid #ddd; }
  table.listC thead td {
    color: #006da8;
    font-weight: bold;
    font-size: 1.2rem; }

table.listC tbody td strong {
  font-size: 1.1rem; }

table.listC tbody tr:last-child td {
  border-bottom: none; }

table.listE td {
  padding: 5px 0 5px 10px; }
  table.listE td.list-ttl {
    width: 50px;
    font-weight: bold; }
  table.listE td.list-ttlB {
    width: 50px;
    font-weight: bold;
    padding: 5px 0 0; }

table.listF td {
  padding: 20px 20px;
  border: 1px solid #eee; }

table.listF thead {
  border: 1px solid #eee;
  background: #ddd !important;
  font-weight: bold; }

table.listG {
  margin: 0 0 20px; }
  table.listG td {
    padding: 10px 20px;
    border: 1px solid #eee; }
  table.listG thead {
    border: 1px solid #eee;
    background: #ddd !important;
    font-weight: bold; }

.table-ttlA {
  width: 100px;
  background: #f7f7f7;
  font-weight: bold; }

.table-ttlB {
  width: 10px;
  background: #ccc;
  font-weight: bold; }

.scroll-y {
  overflow-y: scroll;
  height: 550px; }

.table-list-box {
  width: 100%;
  overflow-x: auto;
  table-layout: fixed; }
  .table-list-box td {
    white-space: nowrap; }
    @media all and (min-width: 0px) and (max-width: 800px) {
      .table-list-box td {
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        max-width: 140px !important;
        white-space: nowrap; } }
  .table-list-box td.wrap-normal {
    white-space: normal;
    min-width: 200px;
    width: 200px;
    word-wrap: break-word; }
  .table-list-box td.wrap-normal_250 {
    white-space: normal;
    min-width: 250px;
    width: 250px;
    word-wrap: break-word; }
  .table-list-box td.wrap-normal_300 {
    white-space: normal;
    min-width: 300px;
    width: 300px;
    word-wrap: break-word; }
  .table-list-box td.wrap-normal_350 {
    white-space: normal;
    min-width: 350px;
    width: 350px;
    word-wrap: break-word; }
  .table-list-box td.wrap-normal_400 {
    white-space: normal;
    min-width: 400px;
    width: 400px;
    word-wrap: break-word; }
  @media all and (min-width: 0px) and (max-width: 800px) {
    .table-list-box td.wrap-normal {
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      min-width: 140px !important;
      width: 140px !important;
      white-space: nowrap; }
    .table-list-box td.wrap-normal_250 {
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      min-width: 140px !important;
      width: 140px !important;
      white-space: nowrap; }
    .table-list-box td.wrap-normal_300 {
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      min-width: 140px !important;
      width: 140px !important;
      white-space: nowrap; }
    .table-list-box td.wrap-normal_350 {
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      min-width: 140px !important;
      width: 140px !important;
      white-space: nowrap; }
    .table-list-box td.wrap-normal_400 {
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      min-width: 140px !important;
      width: 140px !important;
      white-space: nowrap; } }
  .table-list-box.is-disabled td {
    border: none; }

.table-headerfix table {
  border-collapse: separate; }

.table-headerfix thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #f7f7f7;
  z-index: 99; }
  .table-headerfix thead td {
    border-bottom: 1px solid #eee; }

.table-list-box .listC {
  width: 100%;
  overflow-x: auto; }
  .table-list-box .listC td {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap; }

.table-list-boxB {
  width: 100%; }

.table-none span {
  font-size: 1.2rem; }

.icon-table-a {
  background: #f29a00;
  color: #fff;
  padding: 2px 5px; }

.icon-table-b {
  background: #00b254;
  color: #fff;
  padding: 2px 5px; }

.icon-table-c {
  background: #999;
  color: #fff;
  padding: 2px 5px; }

.icon-table-d {
  background: #ff0000;
  color: #fff;
  padding: 2px 5px; }

.status-red {
  color: #ff0000; }

.status-blue {
  color: #006ee9; }

.status-orenge {
  color: #ff9517; }

.status-green {
  color: #00b254; }

.div-jyoukyou-notice {
  color: #ff0000; }

.list-ttlB {
  width: 110px;
  display: block;
  float: left;
  line-height: 2; }

.list-ttlC {
  line-height: 3;
  padding: 0 5px 0 0; }

.form-inbox {
  padding: 3px 0 20px; }

.sum {
  text-align: left;
  font-weight: bold;
  padding: 10px 0 0; }
  .sum span {
    font-size: 2.6rem; }

.sum-box {
  margin: -10px 0 0; }
  .sum-box .form-ttl {
    padding: 20px 0 0 !important; }

.sun-flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.sousa-box {
  position: relative;
  z-index: 990; }
  .sousa-box .sousa-nav-box {
    width: 280px;
    border: 2px solid #0077b7;
    padding: 12px 15px;
    background: #fff;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    overflow: hidden;
    position: absolute;
    top: -35px;
    right: 0px;
    z-index: 990; }
    .sousa-box .sousa-nav-box li {
      text-align: left;
      margin: 0 0 0 0;
      font-weight: bold; }
    .sousa-box .sousa-nav-box li.no-link {
      pointer-events: none; }
      .sousa-box .sousa-nav-box li.no-link a {
        color: #ccc !important; }

.suggest-box {
  position: relative; }
  .suggest-box .suggest-nav-box {
    width: 280px;
    border: 1px solid #ddd;
    padding: 12px 15px;
    background: #fff;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    overflow: hidden;
    position: absolute;
    top: -35px;
    right: 0px;
    z-index: 999; }
    .suggest-box .suggest-nav-box li {
      text-align: left;
      margin: 0 0 0 0; }

.box-search-check {
  padding: 10px 0 30px;
  border-bottom: 1px solid #eee;
  margin: 0 0 0px; }

p.attention {
  color: #aaa; }

.attention-doui {
  color: #ff0054; }

span.attention-b {
  color: #aaa; }

span.attention {
  color: #ff0054; }

.attention-agent-information {
  color: #aaa; }

span.attentionB {
  color: #aaa; }

.attention-box {
  border: 3px solid #ccc;
  color: #888;
  padding: 20px;
  margin: 0 0 40px;
  background: #fff;
  position: relative;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px; }
  .attention-box .ttl-attention {
    font-weight: bold;
    padding: 0 0 10px; }
  .attention-box ul.list-attention li {
    list-style: disc;
    margin: 0 0 0 20px; }
  .attention-box p {
    padding: 0 0 10px; }

.attention-boxB {
  border: 3px solid #ff0054;
  color: #ff0054;
  padding: 20px;
  margin: 0 0 30px;
  background: #fff;
  position: relative;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px; }
  .attention-boxB .ttl-attention {
    font-weight: bold;
    padding: 0 0 10px; }
  .attention-boxB ul.list-attention li {
    list-style: disc;
    margin: 0 0 0 20px; }
  .attention-boxB p {
    padding: 0 0 10px; }

.soukinsaki-attention {
  display: none; }

.error-txt {
  color: #ff0054;
  padding: 5px 0 0; }

.error-close {
  cursor: pointer; }

.clickable:hover {
  background: #ffe9ef;
  cursor: pointer;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.listC .clickable:hover {
  background: #fff;
  cursor: default; }

/*
.btn-file{
	border: 1px solid $main_color;
  background: $main_color;
  color: #fff !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 110px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 0 0 0;
	cursor: pointer;
}*/
.pageNavi {
  overflow: hidden;
  margin: 20px 0 0;
  clear: both;
  text-align: center; }
  .pageNavi span.current {
    background: #0077b7;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    padding: 10px 15px;
    margin: 0 10px 0 0;
    line-height: 3;
    font-size: 1.1em;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px; }
  .pageNavi a {
    background: #ccc;
    color: #fff;
    width: 40px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    padding: 10px 15px;
    margin: 0 10px 0 0;
    line-height: 1.1;
    text-decoration: none;
    font-size: 1.1em;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px; }
    .pageNavi a:hover {
      text-decoration: none !important;
      background: #0077b7; }

/*

float
-----------------------------------*/
.leftBoxA {
  width: 49%;
  float: left; }

.rightBoxA {
  width: 49%;
  float: right; }

.leftBoxB {
  width: 50%;
  float: left;
  padding: 0.5% 2%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.rightBoxB {
  width: 50%;
  float: right;
  border-left: 1px solid #e6e2c9;
  padding: 0.5% 2%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.flex-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.flex-box-cord {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .flex-box-cord .cord-bar {
    padding: 0 5px; }

/*

link
-----------------------------------*/
.link-more {
  position: absolute;
  top: 17px;
  right: 15px; }

.link-gaibu {
  background: url("../img/icon_link.svg") center left no-repeat;
  background-size: 14px auto;
  padding: 0 0 0 20px; }

.link-pdf {
  background: url("../img/icon_pdf.svg") center left no-repeat;
  background-size: 14px auto;
  padding: 0 0 0 20px; }

.link-csv {
  background: url("../img/icon_csv.svg") center left no-repeat;
  background-size: 14px auto;
  padding: 0 0 0 20px; }

@media screen and (min-width: 1080px) {
  /* PC */ }

/*1080px*/
/*

form
-----------------------------------*/
.form-listbox {
  padding: 25px 0 25px 15px;
  border-bottom: 1px solid #eee;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: table; }
  .form-listbox.is-alert {
    background-color: #ffe9ef; }
  .form-listbox.variable-expenses-inovice {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }

.form-listbox-tenpu {
  padding: 25px 0 25px 0;
  border-bottom: 1px solid #eee;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: table; }

.form-ttl {
  width: 140px;
  font-weight: bold;
  display: table-cell;
  vertical-align: middle; }
  .form-ttl.code {
    width: 300px; }
  .form-ttl.tasha-renkei {
    width: 250px; }

.form-ttl-vt {
  width: 140px;
  font-weight: bold;
  display: table-cell;
  vertical-align: top;
  padding: 5px 0 0; }

.form-contents {
  display: table-cell;
  vertical-align: middle;
  word-break: break-all; }
  .form-contents input[type="radio"], .form-contents input[type="check"] {
    vertical-align: -0.1em; }

.col3 {
  width: 102%;
  overflow: hidden;
  padding: 10px 0 20px; }
  .col3 .form-listboxB {
    width: 32.3%;
    float: left;
    margin: 0 1% 0 0; }
  .col3 .form-listboxC {
    width: 32.3%;
    float: left;
    margin: 0 1% 0 0; }

.col4 {
  width: 100%;
  overflow: hidden; }
  .col4 .form-listboxB {
    width: 45%;
    float: left;
    margin: 0 5% 10px 0; }
  .col4 .form-listboxC {
    width: 45%;
    float: right;
    margin: 0 5% 0 0; }
    .col4 .form-listboxC .form-ttlB {
      padding: 20px 0 0 !important; }

.border-bottom {
  border-bottom: 1px solid #eee;
  padding: 0 0 20px; }

.form-listbox {
  display: table; }

.form-ttlB {
  width: 140px;
  font-weight: bold;
  display: table-cell;
  padding: 4px 0 0; }

.form-contentsB {
  display: table-cell;
  vertical-align: middle;
  width: 75%;
  word-break: break-all; }

.form-ttlC {
  width: 200px;
  font-weight: bold;
  display: table-cell;
  padding: 6px 0 0; }

.form-ttlD {
  width: 280px;
  font-weight: bold;
  display: table-cell;
  padding: 6px 0 0; }

.form-ttlE {
  width: 350px;
  font-weight: bold;
  display: table-cell;
  padding: 6px 0 0; }

.number-only {
  ime-mode: inactive; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit; }

input[type="text"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="email"],
input[type="datetime"],
input[type="data"],
input[type="month"],
input[type="week"],
input[type="time"] {
  font: 1.4rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4px 6px;
  border: 1px solid #ddd;
  margin: 0 0 0;
  background: #f8f8f8;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px; }
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="search"]:focus,
  input[type="tel"]:focus,
  input[type="email"]:focus,
  input[type="datetime"]:focus,
  input[type="data"]:focus,
  input[type="month"]:focus,
  input[type="week"]:focus,
  input[type="time"]:focus {
    outline: #ffe9ef;
    background: #ffe9ef; }
    input[type="text"]:focus::after,
    input[type="password"]:focus::after,
    input[type="search"]:focus::after,
    input[type="tel"]:focus::after,
    input[type="email"]:focus::after,
    input[type="datetime"]:focus::after,
    input[type="data"]:focus::after,
    input[type="month"]:focus::after,
    input[type="week"]:focus::after,
    input[type="time"]:focus::after {
      outline: #ffe9ef;
      background: #ffe9ef; }
  input[type="text"][disabled],
  input[type="password"][disabled],
  input[type="search"][disabled],
  input[type="tel"][disabled],
  input[type="email"][disabled],
  input[type="datetime"][disabled],
  input[type="data"][disabled],
  input[type="month"][disabled],
  input[type="week"][disabled],
  input[type="time"][disabled] {
    filter: alpha(opacity=40) !important;
    -moz-opacity: 0.4 !important;
    opacity: 0.4 !important; }
  input[type="text"][readonly],
  input[type="password"][readonly],
  input[type="search"][readonly],
  input[type="tel"][readonly],
  input[type="email"][readonly],
  input[type="datetime"][readonly],
  input[type="data"][readonly],
  input[type="month"][readonly],
  input[type="week"][readonly],
  input[type="time"][readonly] {
    filter: alpha(opacity=40);
    -moz-opacity: 0.4;
    opacity: 0.4; }

input[type="checkbox"][disabled] + span {
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4; }

.error-boader {
  border: 1px solid #ff0054 !important; }

select.select-boxA {
  font: 1.4rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding: 4px 6px;
  margin: -3px 0 0;
  height: 35px;
  background: #f8f8f8;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px; }
  select.select-boxA:focus {
    outline: #ffe9ef;
    background: #ffe9ef; }
    select.select-boxA:focus::after {
      outline: #ffe9ef;
      background: #ffe9ef; }
  select.select-boxA[disabled] {
    filter: alpha(opacity=40);
    -moz-opacity: 0.4;
    opacity: 0.4; }
  select.select-boxA[readonly] {
    filter: alpha(opacity=40);
    -moz-opacity: 0.4;
    opacity: 0.4; }
  select.select-boxA.driver-license {
    border-radius: 3px 0 0 3px; }

.box-yellow input[type="text"],
.box-yellow input[type="password"],
.box-yellow input[type="search"],
.box-yellow input[type="tel"],
.box-yellow input[type="email"],
.box-yellow input[type="datetime"],
.box-yellow input[type="data"],
.box-yellow input[type="month"],
.box-yellow input[type="week"],
.box-yellow input[type="time"],
.content_area input[type="text"],
.content_area input[type="password"],
.content_area input[type="search"],
.content_area input[type="tel"],
.content_area input[type="email"],
.content_area input[type="datetime"],
.content_area input[type="data"],
.content_area input[type="month"],
.content_area input[type="week"],
.content_area input[type="time"] {
  background: #fff; }
  .box-yellow input[type="text"]:focus,
  .box-yellow input[type="password"]:focus,
  .box-yellow input[type="search"]:focus,
  .box-yellow input[type="tel"]:focus,
  .box-yellow input[type="email"]:focus,
  .box-yellow input[type="datetime"]:focus,
  .box-yellow input[type="data"]:focus,
  .box-yellow input[type="month"]:focus,
  .box-yellow input[type="week"]:focus,
  .box-yellow input[type="time"]:focus,
  .content_area input[type="text"]:focus,
  .content_area input[type="password"]:focus,
  .content_area input[type="search"]:focus,
  .content_area input[type="tel"]:focus,
  .content_area input[type="email"]:focus,
  .content_area input[type="datetime"]:focus,
  .content_area input[type="data"]:focus,
  .content_area input[type="month"]:focus,
  .content_area input[type="week"]:focus,
  .content_area input[type="time"]:focus {
    outline: #ffe9ef;
    background: #ffe9ef; }
    .box-yellow input[type="text"]:focus::after,
    .box-yellow input[type="password"]:focus::after,
    .box-yellow input[type="search"]:focus::after,
    .box-yellow input[type="tel"]:focus::after,
    .box-yellow input[type="email"]:focus::after,
    .box-yellow input[type="datetime"]:focus::after,
    .box-yellow input[type="data"]:focus::after,
    .box-yellow input[type="month"]:focus::after,
    .box-yellow input[type="week"]:focus::after,
    .box-yellow input[type="time"]:focus::after,
    .content_area input[type="text"]:focus::after,
    .content_area input[type="password"]:focus::after,
    .content_area input[type="search"]:focus::after,
    .content_area input[type="tel"]:focus::after,
    .content_area input[type="email"]:focus::after,
    .content_area input[type="datetime"]:focus::after,
    .content_area input[type="data"]:focus::after,
    .content_area input[type="month"]:focus::after,
    .content_area input[type="week"]:focus::after,
    .content_area input[type="time"]:focus::after {
      outline: #ffe9ef;
      background: #ffe9ef; }
  .box-yellow input[type="text"][disabled],
  .box-yellow input[type="password"][disabled],
  .box-yellow input[type="search"][disabled],
  .box-yellow input[type="tel"][disabled],
  .box-yellow input[type="email"][disabled],
  .box-yellow input[type="datetime"][disabled],
  .box-yellow input[type="data"][disabled],
  .box-yellow input[type="month"][disabled],
  .box-yellow input[type="week"][disabled],
  .box-yellow input[type="time"][disabled],
  .content_area input[type="text"][disabled],
  .content_area input[type="password"][disabled],
  .content_area input[type="search"][disabled],
  .content_area input[type="tel"][disabled],
  .content_area input[type="email"][disabled],
  .content_area input[type="datetime"][disabled],
  .content_area input[type="data"][disabled],
  .content_area input[type="month"][disabled],
  .content_area input[type="week"][disabled],
  .content_area input[type="time"][disabled] {
    filter: alpha(opacity=40);
    -moz-opacity: 0.4;
    opacity: 0.4; }
  .box-yellow input[type="text"][readonly],
  .box-yellow input[type="password"][readonly],
  .box-yellow input[type="search"][readonly],
  .box-yellow input[type="tel"][readonly],
  .box-yellow input[type="email"][readonly],
  .box-yellow input[type="datetime"][readonly],
  .box-yellow input[type="data"][readonly],
  .box-yellow input[type="month"][readonly],
  .box-yellow input[type="week"][readonly],
  .box-yellow input[type="time"][readonly],
  .content_area input[type="text"][readonly],
  .content_area input[type="password"][readonly],
  .content_area input[type="search"][readonly],
  .content_area input[type="tel"][readonly],
  .content_area input[type="email"][readonly],
  .content_area input[type="datetime"][readonly],
  .content_area input[type="data"][readonly],
  .content_area input[type="month"][readonly],
  .content_area input[type="week"][readonly],
  .content_area input[type="time"][readonly] {
    filter: alpha(opacity=40);
    -moz-opacity: 0.4;
    opacity: 0.4; }

.box-yellow select.select-boxA,
.content_area select.select-boxA {
  background: #fff; }
  .box-yellow select.select-boxA:focus,
  .content_area select.select-boxA:focus {
    outline: #ffe9ef;
    background: #ffe9ef; }
    .box-yellow select.select-boxA:focus::after,
    .content_area select.select-boxA:focus::after {
      outline: #ffe9ef;
      background: #ffe9ef; }

.box-yellow .checkbox-parts::before,
.content_area .checkbox-parts::before {
  background: #fff; }

.box-yellow .radio-input + label::before,
.content_area .radio-input + label::before {
  background: #fff; }

.box-yellow .radio-input:focus + label:before,
.content_area .radio-input:focus + label:before {
  border: 1px solid #ddd;
  border-radius: 50%;
  background: #ffd1de !important; }

.checkbox-input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  overflow: hidden;
  padding: 0;
  clip: rect(0, 0, 0, 0); }
  .checkbox-input.kh-km-code {
    color: grey; }

.checkbox-input + .checkbox-parts {
  display: inline-block;
  position: relative;
  padding-left: 23px;
  cursor: pointer;
  margin: 0 12px 0 0; }

/* 擬似要素で楕円を作る。 */
.checkbox-input + .checkbox-parts:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: contain;
  background: #f8f8f8; }

.fixItem .checkbox-input + .checkbox-parts {
  display: inline-block;
  position: relative;
  padding-left: 0px;
  cursor: pointer;
  margin: 0; }

.fixItem .checkbox-input + .checkbox-parts:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 2px;
  bottom: 0;
  left: 0;
  width: 1em;
  height: 1em;
  margin: 0 4px 0 0;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: contain;
  background: #f8f8f8; }

.fixItem .checkbox-parts .checkbox-txt {
  margin: -23px 0 0 23px;
  display: block; }

.box-yellow .checkbox-input + .checkbox-parts {
  display: inline-block;
  position: relative;
  padding-left: 0px;
  cursor: pointer;
  margin: 0; }

.box-yellow .checkbox-input + .checkbox-parts:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 2px;
  bottom: 0;
  left: 0;
  width: 1em;
  height: 1em;
  margin: 0 4px 0 0;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: contain;
  background: #f8f8f8; }

.box-yellow .checkbox-parts .checkbox-txt {
  margin: -23px 0 0 23px;
  display: block; }

/* フォーカスが当たると楕円の色などを変える。 */
.checkbox-input:focus + .checkbox-parts:before {
  border-color: #1589ee;
  border: 1px solid #ddd;
  /*box-shadow: 0 0 5px #1589ee;*/
  background: #ffe9ef; }

/* チェックボックスがチェックされるとボーダーは非表示、画像でチェックボックスを表示する。 */
.checkbox-input:checked + .checkbox-parts:before {
  border: 1px solid #ddd; }

.checkbox-input:checked + .checkbox-parts::after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 5px;
  width: 4px;
  height: 12px;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
  border-bottom: 3px solid #0077b7;
  border-right: 3px solid #0077b7; }

td .checkbox-parts {
  position: relative;
  margin-right: 0px;
  padding: 20px; }

td .checkbox-parts::before {
  content: "";
  display: block;
  position: absolute;
  top: 7px !important;
  left: 12px;
  width: 14px;
  height: 14px;
  border: 1px solid #ddd;
  background: #f8f8f8; }

td .checkbox-input:checked + .checkbox-parts::after {
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  left: 5px;
  width: 4px;
  height: 12px;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
  border-bottom: 3px solid #0077b7;
  border-right: 3px solid #0077b7; }

.cbox .checkbox-parts {
  padding-left: 35px;
  position: relative;
  margin-right: 35px; }

.cbox .checkbox-parts::before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  background: #f8f8f8; }

.cbox .checkbox-input:checked + .checkbox-parts::after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 8px;
  width: 4px;
  height: 12px;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
  border-bottom: 3px solid #0077b7;
  border-right: 3px solid #0077b7; }

.cbox .checkbox-input:focus + span:before {
  border-color: #1589ee;
  border: 2px solid #ddd;
  /*box-shadow: 0 0 5px #1589ee;*/
  background: #ffe9ef !important; }

.koushin-check {
  border-bottom: 1px solid #ddd;
  padding: 0 0 30px !important; }
  .koushin-check .checkbox-parts {
    padding-left: 35px;
    position: relative;
    margin-right: 35px; }
  .koushin-check .checkbox-parts::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #ddd;
    background: #f8f8f8; }
  .koushin-check .checkbox-input:checked + .checkbox-parts::after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 8px;
    width: 4px;
    height: 12px;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    border-bottom: 3px solid #0077b7;
    border-right: 3px solid #0077b7; }
  .koushin-check .checkbox-input:focus + span:before {
    border-color: #1589ee;
    border: 2px solid #ddd;
    /*box-shadow: 0 0 5px #1589ee;*/
    background: #ffe9ef !important; }

.radio-input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  overflow: hidden;
  padding: 0;
  clip: rect(0, 0, 0, 0); }

.radio-input + label {
  display: inline-block;
  padding-left: 20px;
  cursor: pointer;
  margin-right: 20px;
  position: relative; }

.radio-space .radio-input + label {
  margin-bottom: 7px; }

.radio-input + label:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1em;
  height: 1em;
  margin: auto;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.radio-input:focus + label:before {
  border: 1px solid #ddd;
  border-radius: 50%;
  /*box-shadow: 0 0 5px #1589ee;*/
  background: #ffd1de; }

.radio-input:checked + label:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 7px;
  bottom: 0;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #1589ee;
  position: absolute; }

.radio-input:disabled + label {
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4; }

.radio-input:disabled + label::before {
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
  opacity: 0.8; }

select.select-list {
  background: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  width: 100%; }
  select.select-list:focus {
    outline: #ffe9ef;
    background: #ffe9ef; }
    select.select-list:focus::after {
      outline: #ffe9ef;
      background: #ffe9ef; }

.box-yellow .radio-input + label::before,
.content_area .radio-input + label::before {
  background: #fff !important; }

.box-yellow .radio-input:focus + label:before,
.content_area .radio-input:focus + label:before {
  border: 1px solid #ddd;
  border-radius: 50%;
  background: #ffd1de !important; }

.box-yellow .label,
.content_area .label {
  margin-left: 10px; }

textarea {
  font: 1.4rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4px 6px;
  border: 1px solid #ddd;
  margin: 0 0 0;
  padding: 15px;
  background: #f8f8f8;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px; }

.width10 {
  width: 10%;
  min-width: 60px; }

.width15 {
  width: 15%; }

.width20 {
  width: 20%;
  min-width: 60px; }

.width25 {
  width: 25%; }

.width30 {
  width: 30%; }

.width35 {
  width: 35%; }

.width40 {
  width: 40%; }

.width45 {
  width: 45%; }

.width50 {
  width: 50%; }

.width55 {
  width: 55%; }

.width60 {
  width: 60%; }

.width65 {
  width: 65%; }

.width70 {
  width: 70%; }

.width75 {
  width: 75%; }

.width80 {
  width: 80%; }

.width85 {
  width: 85%; }

.width90 {
  width: 90%; }

.width95 {
  width: 95%; }

.width100 {
  width: 100%; }
  .width100.register-number {
    padding: 4px;
    margin-left: 5px; }
  .width100.driver-license-renewal {
    border: none;
    height: 33px;
    padding: 4px 15px; }
  .width100.driver-license-number {
    border: none;
    padding: 4px 20px; }
  .width100.license-type-takuken {
    border: none;
    text-align: center;
    width: 50px; }

input.widthFull-110 {
  width: calc(100% - 110px); }
  @media screen and (min-width: 0px) and (max-width: 580px) {
    input.widthFull-110 {
      width: 100%; } }

.col2 {
  overflow: hidden;
  border-bottom: 1px solid #eee;
  clear: both; }
  .col2.kh-code, .col2.address, .col2.business-operator, .col2.takuchi, .col2.establishment-date {
    border: none; }
  .col2.check-invoice {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end; }
  .col2 .left {
    width: 47% !important;
    float: left;
    border: none; }
  .col2 .right {
    width: 47% !important;
    float: right;
    border: none; }
  .col2 .check-left {
    width: 50px;
    float: left;
    padding: 35px 0 0 20px; }
    .col2 .check-left .checkbox-input:checked + .checkbox-parts::after {
      content: "";
      display: block;
      position: absolute;
      top: -8px;
      left: 5px;
      width: 4px;
      height: 12px;
      -webkit-transform: rotate(40deg);
      -ms-transform: rotate(40deg);
      transform: rotate(40deg);
      border-bottom: 3px solid #0077b7;
      border-right: 3px solid #0077b7; }
  .col2 .check-right {
    width: calc(100% - 70px);
    float: right; }

.code-input-form {
  background-color: red; }

.col3 {
  overflow: hidden;
  border-bottom: 1px solid #eee;
  clear: both; }
  .col3 .left {
    width: 33% !important;
    float: left;
    border: none; }

.error-box {
  border: 3px solid #ff0054;
  color: #ff0054;
  padding: 20px;
  margin: 0 0 30px;
  background: #fff;
  position: relative;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px; }
  .error-box.invoice {
    margin: 0px; }
  .error-box .ttl-error {
    font-weight: bold;
    padding: 0 0 10px; }
  .error-box ul.list-error li {
    list-style: disc;
    margin: 0 0 2px 20px;
    width: 98%; }
    .error-box ul.list-error li .list-error-box {
      display: table;
      width: 100%; }
    .error-box ul.list-error li .error-list-txt {
      display: table-cell;
      width: 95%; }
  .error-box .btn-kakunin-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .error-box .btn-kakunin {
    border: 0;
    background: #ff0054;
    color: #fff !important;
    padding: 2px 3px;
    display: block;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    width: 90px;
    font-size: 1.1rem;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    margin: 0 0 1px;
    cursor: pointer; }
  .error-box .btn-kakunin-tuika {
    border: 0;
    background: #0077b7;
    color: #fff !important;
    padding: 7px 5px 0 !important;
    display: block;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    width: 120px;
    font-size: 1.2rem;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    margin: 0 0 1px 15px;
    cursor: pointer; }
  .error-box .btn-delete {
    border: 0;
    padding: 0 0 0 4px;
    background: #fff;
    cursor: pointer; }
  .error-box .label-kakunin {
    background: #00b254;
    color: #fff;
    padding: 2px 3px;
    text-align: center;
    font-weight: bold;
    text-align: center;
    width: 90px;
    font-size: 1.1rem;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    margin: 0 0 1px; }
  .error-box .btn-flexbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 20px 0 0; }

.error-txt {
  color: #ff0054;
  padding: 5px 0 0; }
  .error-txt.kh-km-code {
    color: #aaa; }

img.ui-datepicker-trigger {
  cursor: pointer;
  margin-left: 7px !important;
  vertical-align: middle;
  width: 30px;
  height: 30px; }

.koushin-check {
  text-align: center;
  font-weight: bold;
  padding: 0 0 10px;
  font-size: 1.8rem; }

.kousin-yen {
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem; }

.kousin-yen span {
  font-size: 2.4rem; }

#hiwari-box {
  display: none; }

.hiwari-yen {
  text-align: left;
  font-weight: bold;
  font-size: 1.4rem; }

.hiwari-yen span {
  font-size: 2.4rem; }

/*

icon
-----------------------------------*/
.fa-user {
  background: url("../img/icon_user.svg") center center no-repeat;
  background-size: 25px auto;
  padding: 7px;
  width: 30px;
  height: 30px;
  display: block;
  margin: 0 auto; }

.fa-system {
  background: url("../img/icon_system.svg") center center no-repeat;
  background-size: 27px auto;
  padding: 5px;
  width: 30px;
  height: 30px;
  display: block;
  margin: 0 auto; }

.fa-download {
  background: url("../img/icon_download02.svg") center center no-repeat;
  background-size: 20px auto;
  padding: 5px 13px;
  width: 14px;
  height: 14px; }

.fa-downloadB {
  background: url("../img/icon_download03.svg") center center no-repeat;
  background-size: 26px auto;
  padding: 5px 17px;
  width: 16px;
  height: 16px; }

.fa-pdf {
  background: url("../img/icon_pdf02.svg") center center no-repeat;
  background-size: 20px auto;
  padding: 5px 13px;
  width: 14px;
  height: 14px; }

.fa-pdfB {
  background: url("../img/icon_pdf.svg") center center no-repeat;
  background-size: 20px auto;
  padding: 5px 13px;
  width: 14px;
  height: 14px; }

.fa-openA {
  background: url("../img/icon_open.svg") center center no-repeat;
  background-size: 20px auto;
  padding: 5px;
  width: 30px;
  height: 30px; }

.fa-openB {
  background: url("../img/icon_open02.svg") center center no-repeat;
  background-size: 19px auto;
  padding: 0 20px;
  width: 30px;
  height: 10px; }

.arrow {
  background: url("../img/icon_arrow.svg") center left no-repeat;
  background-size: 8px auto;
  padding: 0 0 0 16px; }

.icon-download {
  background: url("../img/icon_download02.svg") center left -3px no-repeat;
  background-size: 16px auto;
  padding: 0 0 0 16px; }

.txt-area p {
  padding: 0 0 10px; }

.download-link {
  text-align: center; }

/*

btn
-----------------------------------*/
.btn-boxA {
  max-width: 420px;
  margin: 0 auto;
  overflow: hidden;
  padding: 15px 0 10px;
  clear: both; }

.btn-boxB {
  overflow: hidden;
  padding: 15px 0 5px;
  clear: both; }

.btn-boxC {
  padding: 10px 0 5px;
  text-align: center;
  position: relative;
  line-height: 2.5; }
  .btn-boxC button {
    display: inline-block;
    margin: 0 5px 10px; }
  .btn-boxC a {
    display: inline-block;
    margin: 0 5px; }

.btn-boxD {
  margin: 0 0 50px;
  text-align: center;
  position: relative;
  line-height: 2.5; }
  .btn-boxD button {
    display: inline-block;
    margin: 0 5px; }
  .btn-boxD a {
    display: inline-block;
    margin: 0 5px; }

.btn-left {
  position: absolute;
  left: 0; }

.btn-right {
  position: absolute;
  right: 0; }

.btn-keiyakubox {
  margin: 0 0 50px;
  text-align: left; }
  .btn-keiyakubox .info {
    font-weight: bold;
    padding: 0 0 20px; }
  .btn-keiyakubox .inner {
    padding: 15px 0 5px;
    border-top: 1px solid #eee; }
    .btn-keiyakubox .inner .btn-box-inner {
      float: left;
      padding: 10px 15px 10px 23px;
      margin: 0 17px 8px 0;
      background: #f0f2f8;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px; }
      .btn-keiyakubox .inner .btn-box-inner a {
        margin: 0 15px 0 0; }
    .btn-keiyakubox .inner button {
      display: inline-block;
      margin: 0 5px 10px; }

.btn-logout a {
  color: #7889a3 !important;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 100px;
  font-size: 1.1rem;
  position: absolute;
  top: 38px;
  right: 80px;
  overflow: hidden; }

.btn-system a {
  color: #7889a3 !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 100px;
  font-size: 1.1rem;
  position: absolute;
  top: 38px;
  right: 10px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-small-black {
  border: 1px solid #03bf03;
  background: #03bf03;
  color: #fff !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 100px;
  font-size: 1.1rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 15px 0 0;
  cursor: pointer; }
  .btn-small-black:hover {
    opacity: 1 !important;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; }

#btn-lock-off:hover {
  opacity: 1 !important;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

.btn-bg-orenge {
  border: 1px solid #fa8200;
  background: #fa8200; }

.btn-small-red {
  border: 1px solid #dc143c;
  background: #dc143c;
  color: #fff !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 110px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 0 0 0;
  cursor: pointer; }
  .btn-small-red.cancel {
    width: 150px;
    padding: 5px; }

.btn-small-blue {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 110px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 0 0 0;
  cursor: pointer; }
  .btn-small-blue.contract {
    width: 150px;
    padding: 5px; }
  .btn-small-blue.setting {
    padding: 5px; }
  .btn-small-blue.reference {
    width: 80px;
    margin-bottom: 13px; }

.btn-small-blue:disabled {
  cursor: default;
  pointer-events: none;
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4; }

.btn-small-blueB {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 170px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 3px 0 0 0;
  cursor: pointer; }

.btn-small-blueC {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  position: absolute;
  top: 13px;
  right: 50px;
  width: 220px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 3px 0 0 0;
  cursor: pointer;
  　z-index: 9999; }

.btn-small-blueC02 {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  position: absolute;
  top: 13px;
  right: 50px;
  width: 160px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 3px 0 0 0;
  cursor: pointer;
  　z-index: 9999; }

.btn-small-blueD {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  position: absolute;
  top: 9px;
  right: 8px;
  width: 180px;
  font-size: 1.3rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 3px 0 0 0;
  cursor: pointer; }
  .btn-small-blueD.agent-information-configured {
    background: #E0F5FF;
    color: #0077b7 !important; }

.btn-small-whiteD {
  border: 1px solid #0077b7;
  background: #fff;
  color: #0077b7 !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  position: absolute;
  top: 9px;
  right: 8px;
  width: 70px;
  font-size: 1.3rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 3px 0 0 0;
  cursor: pointer; }

.btn-small-blueE {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 80px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 0 0 0;
  cursor: pointer; }

.btn-small-blueF {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 3px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 80px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 0 0 10px;
  cursor: pointer; }
  .btn-small-blueF:hover {
    filter: alpha(opacity=70);
    -moz-opacity: 0.7;
    opacity: 0.7;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; }

.btn-small-blueG {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 3px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 70px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 0 0 15px;
  cursor: pointer; }

.btn-small-blueH {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 3px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 140px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 0 0 10px;
  cursor: pointer; }

@media screen and (max-width: 800px) {
  .btn-small-blueH {
    border: 1px solid #0077b7;
    background: #0077b7;
    color: #fff !important;
    padding: 3px;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    width: 130px;
    font-size: 1.3rem;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    overflow: hidden;
    margin: 0 0 0 10px;
    cursor: pointer; } }

@media screen and (max-width: 770px) {
  .btn-small-blueH {
    margin: 10px 0 0 0; } }

.btn-middle-blue {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 240px;
  font-size: 1.6rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 auto !important; }

.btn-middle-blueA {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 120px;
  font-size: 1.6rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 auto !important; }

.btn-middle-blueB {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 330px;
  font-size: 1.6rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 auto !important; }

.btn-large-white {
  border: 1px solid #0077b7;
  background: #fff;
  color: #0077b7 !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 200px;
  font-size: 1.8rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-large-white:disabled {
  cursor: default;
  pointer-events: none;
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4; }

.btn-large-whiteB {
  border: 1px solid #0077b7;
  background: #fff;
  color: #0077b7 !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 260px;
  font-size: 1.8rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-large-whiteC {
  border: 1px solid #0077b7;
  background: #fff;
  color: #0077b7 !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 150px;
  font-size: 1.8rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-large-whiteD {
  border: 1px solid #0077b7;
  background: #fff;
  color: #0077b7 !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 300px;
  font-size: 1.8rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-large-blue {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 200px;
  font-size: 1.8rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-large-blue:disabled {
  cursor: default;
  pointer-events: none;
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4; }

.btn-large-redB:disabled {
  cursor: default;
  pointer-events: none;
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4; }

#btn-moushikomi:disabled {
  cursor: default;
  pointer-events: none;
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4; }

#txtbox-ryousyuusyo:disabled {
  cursor: default;
  pointer-events: none;
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4; }

#btn-keiyaku-kanryou:disabled {
  cursor: default;
  pointer-events: none;
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4; }

#btn-keiyaku-term:disabled {
  cursor: default;
  pointer-events: none;
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4; }

.btn-large-blueB {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 350px;
  font-size: 1.8rem;
  margin: 0 auto;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-large-blueC {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 300px;
  font-size: 1.8rem;
  margin: 0 auto;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-large-blueD {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 400px;
  font-size: 1.8rem;
  margin: 0 auto;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-large-blueE {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 12px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 350px;
  font-size: 1.8rem;
  margin: 0 auto;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-large-greyB {
  border: 1px solid #999;
  background: #999;
  color: #fff !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 200px;
  font-size: 1.8rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  pointer-events: none; }

.btn-large-greyC {
  border: 1px solid #999;
  background: #999;
  color: #fff !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 350px;
  margin: 0 auto;
  font-size: 1.8rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  pointer-events: none; }

.btn-large-greyD {
  border: 1px solid #666;
  background: #666;
  color: #fff !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 150px;
  font-size: 1.8rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-large-red {
  border: 1px solid #ff467a;
  background: #ff467a;
  color: #fff !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 500px;
  font-size: 2rem;
  margin: 0 auto;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-large-red:disabled {
  cursor: default; }

.btn-large-redB {
  border: 1px solid #f00;
  background: #fff;
  color: #f00 !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 200px;
  font-size: 1.8rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-large-redC {
  border: 1px solid #ff0054;
  background: #ff0054;
  color: #fff !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 300px;
  font-size: 1.8rem;
  margin: 0 auto;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-large-redD {
  border: 1px solid #ff0054;
  background: #ff0054;
  color: #fff !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 200px;
  font-size: 1.8rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-large-redD:disabled {
  cursor: default;
  pointer-events: none;
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4; }

.btn-large-grey {
  float: left;
  width: 23.5%;
  margin: 0 1.5% 0 0; }
  .btn-large-grey a {
    background: #f0f2f8;
    color: #0077b7;
    padding: 15px 15px;
    display: block;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    overflow: hidden;
    cursor: pointer; }

.btn-large-grey_hm {
  float: left;
  width: 21.5%;
  height: 90px;
  margin: 0 1.5% 10px 0;
  background: #f0f2f8;
  color: #0077b7;
  padding: 15px 1% 25px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  overflow: hidden; }
  .btn-large-grey_hm a {
    display: block;
    padding: 3px 0;
    font-size: 15px;
    overflow: hidden;
    text-decoration: none;
    text-align: center;
    font-weight: bold; }

.btn-large-grey_hmB {
  float: left;
  width: 21.5%;
  margin: 0 1.5% 10px 0;
  background: #f0f2f8;
  color: #0077b7;
  padding: 15px 1%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  overflow: hidden; }
  .btn-large-grey_hmB a {
    display: block;
    padding: 3px 0;
    font-size: 15px;
    overflow: hidden;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    cursor: pointer; }

.btn-large-grey_hmC {
  float: left;
  width: 21.5%;
  margin: 0 1.5% 10px 0; }
  .btn-large-grey_hmC a {
    color: #0077b7;
    width: 95%;
    display: block;
    padding: 18px 5%;
    margin: 0 0 10px;
    font-size: 15px;
    overflow: hidden;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    background: #f0f2f8;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    overflow: hidden; }

.btn-large-grey_box {
  float: left;
  width: 21.5%;
  margin: 0 1.5% 0 0; }
  .btn-large-grey_box .btn-large-grey_hmB {
    float: left;
    width: 98.5%;
    margin: 0 1.5% 10px 0;
    background: #f0f2f8;
    color: #0077b7;
    padding: 15px 4%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    overflow: hidden; }
    .btn-large-grey_box .btn-large-grey_hmB a {
      display: block;
      padding: 3px 0;
      font-size: 15px;
      overflow: hidden;
      text-decoration: none;
      text-align: center;
      font-weight: bold;
      cursor: pointer; }

@media all and (min-width: 0px) and (max-width: 1400px) {
  .btn-large-grey_hm {
    float: left;
    width: 44%;
    height: 90px;
    margin: 0 2% 10px 0;
    background: #f0f2f8;
    color: #0077b7;
    padding: 15px 2% 25px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    overflow: hidden; }
    .btn-large-grey_hm a {
      display: block;
      padding: 3px 0;
      font-size: 15px;
      overflow: hidden;
      text-decoration: none;
      text-align: center;
      font-weight: bold; }
  .btn-large-grey_hmB {
    float: left;
    width: 44%;
    margin: 0 2% 10px 0;
    background: #f0f2f8;
    color: #0077b7;
    padding: 15px 2%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    overflow: hidden; }
    .btn-large-grey_hmB a {
      display: block;
      padding: 3px 0;
      font-size: 15px;
      overflow: hidden;
      text-decoration: none;
      text-align: center;
      font-weight: bold;
      cursor: pointer; }
  .btn-large-grey_hmC {
    float: left;
    width: 48%;
    margin: 0 2% 10px 0; }
    .btn-large-grey_hmC a {
      color: #0077b7;
      width: 90%;
      display: block;
      padding: 18px 5%;
      margin: 0 0 10px;
      font-size: 15px;
      overflow: hidden;
      text-decoration: none;
      text-align: center;
      font-weight: bold;
      background: #f0f2f8;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      overflow: hidden; }
  .btn-large-grey_box {
    float: left;
    width: 46%;
    margin: 0 2% 0 0; }
    .btn-large-grey_box .btn-large-grey_hmB {
      float: left;
      width: 98.5%;
      margin: 0 1.5% 10px 0;
      background: #f0f2f8;
      color: #0077b7;
      padding: 15px 4%;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      overflow: hidden; }
      .btn-large-grey_box .btn-large-grey_hmB a {
        display: block;
        padding: 3px 0;
        font-size: 15px;
        overflow: hidden;
        text-decoration: none;
        text-align: center;
        font-weight: bold;
        cursor: pointer; } }

.btn-download {
  position: absolute;
  top: 20px;
  left: 30px;
  border: 1px solid #0077b7;
  background: #fff;
  color: #0077b7 !important;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 90px;
  height: 90px;
  font-size: 1.1rem;
  line-height: 1.4;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  overflow: hidden;
  padding: 20px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.btn-download img {
  padding: 0 0 5px; }

.btn-downloadB {
  position: absolute;
  top: 30px;
  left: 30px;
  border: 1px solid #0077b7;
  background: #fff;
  color: #0077b7 !important;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 110px;
  height: 90px;
  font-size: 1.1rem;
  line-height: 1.4;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  overflow: hidden;
  padding: 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .btn-downloadB.moushikomi-irai {
    bottom: 35px;
    top: initial; }

.btn-downloadB img {
  padding: 0 0 5px; }

.btn-detail {
  position: absolute;
  top: 30px;
  left: 160px;
  border: 1px solid #0077b7;
  background: #fff;
  color: #0077b7 !important;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 110px;
  height: 90px;
  font-size: 1.1rem;
  line-height: 1.4;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  overflow: hidden;
  padding: 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .btn-detail.moushikomi-irai {
    bottom: 35px;
    top: initial; }

.btn-detail img {
  padding: 0 0 13px; }

.btn-downloadC {
  border: 1px solid #0077b7;
  background: #fff;
  color: #0077b7 !important;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 250px;
  height: auto;
  font-size: 1.2rem;
  line-height: 1.2;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  overflow: hidden;
  padding: 10px 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 5px; }
  .btn-downloadC .btn-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .btn-downloadC .btn-inner br {
      display: none; }
.btn-downloadC img {
  width: 20px;
  height: auto;
  padding: 0 5px 0 0; }

@media all and (min-width: 0px) and (max-width: 1150px) {
  .btn-downloadB {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    top: auto;
    left: auto;
    border: 1px solid #0077b7;
    background: #fff;
    color: #0077b7 !important;
    display: block;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    width: 250px;
    height: auto;
    font-size: 1.2rem;
    line-height: 1.2;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    overflow: hidden;
    padding: 10px 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 5px; }
    .btn-downloadB .btn-inner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .btn-downloadB .btn-inner br {
        display: none; }
  .btn-downloadB img {
    width: 20px;
    height: auto;
    padding: 0 5px 0 0; }
  .btn-detail {
    position: relative;
    top: auto;
    left: auto;
    border: 1px solid #0077b7;
    background: #fff;
    color: #0077b7 !important;
    display: block;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    width: 250px;
    height: auto;
    font-size: 1.2rem;
    line-height: 1.2;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    overflow: hidden;
    padding: 10px 15px;
    margin: 0 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
    .btn-detail .btn-inner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
  .btn-detail img {
    width: 20px;
    height: auto;
    padding: 0 5px 0 0; }
  .btn-footeArea-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; } }

.btn-keiyaku-blue {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 250px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 0 10px 0;
  cursor: pointer; }

.btn-keiyaku-blueB {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 250px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 0 10px 0;
  cursor: pointer; }

.btn-keiyaku-blueC {
  border: 1px solid #0077b7;
  background: #fff;
  color: #0077b7 !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 250px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 0 10px 0;
  cursor: pointer; }

.btn-keiyaku-red {
  border: 1px solid #f00;
  background: #fff;
  color: #f00 !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 250px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 0 10px 0;
  cursor: pointer; }

.btn-keiyaku-redB {
  border: 1px solid #ff0054;
  background: #ff0054;
  color: #fff !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 250px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 0 10px 0;
  cursor: pointer; }

.btn-keiyaku-white {
  border: 1px solid #0077b7;
  background: #fff;
  color: #0077b7 !important;
  padding: 3px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 200px;
  font-size: 1.4rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 0 10px 0;
  cursor: pointer; }

.btn-disabled {
  cursor: default;
  pointer-events: none;
  filter: alpha(opacity=40);
  -moz-opacity: 0.4;
  opacity: 0.4; }

#rs-btn-mail {
  display: none; }

input[type="button"],
input[type="submit"],
button[type="button"],
button[type="submit"] {
  cursor: pointer;
  outline: 0; }

input[type="button"]:hover,
input[type="submit"]:hover,
button[type="button"]:hover,
button[type="submit"]:hover {
  filter: alpha(opacity=70);
  -moz-opacity: 0.7;
  opacity: 0.7;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

input[type="file"]:focus {
  outline: 0 !important; }

.m-center {
  width: 200px;
  margin: 0 auto !important; }

.btn-large-grey a .floL {
  font-size: 1.6rem;
  padding: 3px 0 0; }

.btn-large-grey a span {
  font-size: 1.8rem; }

.btn-boxB {
  width: 101.5%; }

.btn-open a {
  border: 1px solid #0077b7;
  background: #0077b7 url("../img/icon_open.svg") center right 5px no-repeat;
  background-size: 23px auto;
  color: #fff !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 170px;
  font-size: 1.6rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 auto;
  clear: both; }

.btn-open a.active {
  border: 1px solid #0077b7;
  background: #0077b7 url("../img/icon_close.svg") center right 5px no-repeat;
  background-size: 23px auto; }

.btn-open-department-news a {
  border: 1px solid #0077b7;
  background: #0077b7 url("../img/icon_open.svg") center right 5px no-repeat;
  background-size: 23px auto;
  color: #fff !important;
  padding: 5px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 170px;
  font-size: 1.6rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin: 0 auto;
  clear: both; }

.btn-open-department-news a.active {
  border: 1px solid #0077b7;
  background: #0077b7 url("../img/icon_close.svg") center right 5px no-repeat;
  background-size: 23px auto; }

.ttl-box {
  position: relative; }

.btn-moushikomi {
  position: absolute;
  top: 0;
  right: 0; }

.btn-yakkan-download {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 7px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 500px;
  font-size: 1.6rem;
  margin: 0 auto 10px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-kiyaku-download {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 7px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: 350px;
  font-size: 1.6rem;
  margin: 0 auto 10px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }

.btn-download--middle {
  border: 1px solid #0077b7;
  background: #0077b7;
  color: #fff !important;
  padding: 7px;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 350px;
  font-size: 1.6rem;
  margin: 0 auto 10px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden; }
  .btn-download--middle.is-checked:after {
    content: '（確認済み）'; }

/*

modal
-----------------------------------*/
.modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  display: none;
  z-index: 9999; }
  .modal .inner {
    position: relative;
    z-index: 11;
    width: 400px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 999999;
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    overflow: hidden; }
    .modal .inner h2 {
      font-size: 18px !important;
      padding: 0 0 15px; }
    .modal .inner p {
      padding: 0 0 15px; }
  .modal .innerB {
    position: relative;
    z-index: 11;
    width: 600px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 999999;
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    overflow: hidden; }
    .modal .innerB h2 {
      font-size: 20px !important;
      padding: 0 0 0; }
    .modal .innerB p {
      padding: 0 0 15px; }
  .modal .inner-search {
    position: relative;
    z-index: 11;
    width: 90%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 999999;
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    height: 80%; }
    .modal .inner-search .inner-scroll {
      overflow: auto;
      height: 92%;
      padding: 0 10px 0 0; }
    .modal .inner-search h2 {
      font-size: 22px !important;
      padding: 0 0 15px;
      color: #0077b7; }
    .modal .inner-search p {
      padding: 0 0 15px; }

.modal-btn-cloase {
  position: absolute;
  top: 10px;
  right: 10px; }

.overLay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999; }

.modal input {
  filter: alpha(opacity=100) !important;
  -moz-opacity: 1 !important;
  opacity: 1 !important;
  background: #fff !important; }

@media (min-width: 768px) {
  a[href^="tel:"] {
    pointer-events: none; } }

@media all and (min-width: 0px) and (max-width: 890px) {
  .btn-boxC button {
    margin: 0 5px 10px; }
  .btn-boxD {
    margin: 0 0 15px; }
    .btn-boxD button {
      margin: 0 5px 10px; }
  .btn-left {
    position: relative;
    padding: 0 0 20px; }
  .btn-right {
    position: relative; } }

@media all and (min-width: 0px) and (max-width: 1081px) {
  .header-txt {
    width: 60%;
    font-size: 10px;
    position: absolute;
    top: 30px;
    right: 20px;
    padding: 0 0 0 0;
    text-align: right; }
    .header-txt br {
      display: none; }
  /*

footer
-----------------------------------*/
  footer {
    text-align: left;
    background-color: #fff;
    font-size: 1.1rem;
    padding: 40px;
    position: relative;
    z-index: 9988;
    margin: 50px 0 0; }
    footer nav {
      width: 400px;
      overflow: hidden;
      margin: 0 0 10px; }
      footer nav li {
        float: left;
        margin: 0 15px 5px 0; }
        footer nav li a {
          font-size: 1.2rem; }
  /*

main
-----------------------------------*/
  .main {
    padding: 20px 40px 40px;
    margin: 0 0 0 60px; }
  .application-box {
    max-width: 900px;
    margin: 80px 30px 100px; }
  .kakunin-box {
    max-width: 900px;
    margin: 50px 30px 100px; }
  /*

col
-----------------------------------*/
  .col2 {
    overflow: hidden;
    border-bottom: none; }
    .col2 .left,
    .col2 .right {
      width: 100% !important;
      border-bottom: 1px solid #eee; }
  .nks-box .left {
    width: 100% !important;
    border-bottom: 1px solid #ddd !important; }
  .taishou-keiyaku-box {
    overflow: hidden;
    border-bottom: 1px solid #eee; }
    .taishou-keiyaku-box .left,
    .taishou-keiyaku-box .right {
      width: 100% !important;
      border-bottom: none !important; }
    .taishou-keiyaku-box .check-left {
      width: 50px;
      float: left;
      padding: 30px 0 0 20px; }
    .taishou-keiyaku-box .check-right {
      width: 80%;
      float: right; }
  .col3 .form-listboxB {
    width: 100%;
    float: none;
    margin: 0 0 10px 0; }
  .col3 .form-listboxC {
    width: 100%;
    float: none;
    margin: 0 0 10px 0; }
  .col4 .form-listboxB {
    width: 100% !important;
    float: none;
    margin: 0 5% 10px 0; }
  .col4 .form-listboxC {
    width: 100%;
    float: none;
    margin: 0 5% 10px 0; }
  .form-listbox {
    padding: 15px 0 15px 0;
    border-bottom: 1px solid #eee;
    width: 100%;
    display: table; }
  .sidebar {
    width: 60px;
    overflow: hidden; }
    .sidebar nav li a {
      height: 25px; }
      .sidebar nav li a span {
        font-size: 1.4rem;
        display: none; }
  .siten-box {
    display: none; }
  #menuButton {
    display: block;
    width: 100%;
    height: 40px;
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 9995;
    filter: alpha(opacity=50);
    -moz-opacity: 1;
    opacity: 1; }
  /*
    #menuButton:hover span:first-child {
      width: 13px;
      -webkit-transform: translateX(1px) translateY(13px) rotate(-45deg);
      -moz-transform: translateX(1px) translateY(13px) rotate(-45deg);
      -ms-transform: translateX(1px) translateY(13px) rotate(-45deg);
      transform: translateX(1px) translateY(13px) rotate(-45deg);
    }
    #menuButton:hover span:last-child {
      width: 13px;
      -webkit-transform: translateX(1px) translateY(-13px) rotate(45deg);
      -moz-transform: translateX(1px) translateY(-13px) rotate(45deg);
      -ms-transform: translateX(1px) translateY(-13px) rotate(45deg);
      transform: translateX(1px) translateY(-13px) rotate(45deg);
    }
  	*/
  .btnI a .floL {
    font-size: 1.6rem;
    padding: 3px 0 0;
    width: 100%;
    text-align: left; }
  .step-box02 {
    z-index: 21;
    margin: 0 30px 0 0;
    width: 160px;
    position: absolute;
    bottom: 110px;
    right: -15px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px; }
    .step-box02 .step-nav {
      position: relative; }
    .step-box02 .step-nav ol {
      border: 1px solid #0077b7;
      background-color: #fff; }
      .step-box02 .step-nav ol li {
        position: relative;
        border-bottom: solid 1px #0077b7;
        color: #0077b7;
        font-weight: bold;
        font-size: 1rem; }
        .step-box02 .step-nav ol li:last-child {
          border: none; }
        .step-box02 .step-nav ol li a {
          background: #fff;
          display: block;
          padding: 3px 10px;
          color: #0077b7; }
          .step-box02 .step-nav ol li a.current {
            background: #0077b7;
            display: block;
            padding: 3px 10px;
            color: #fff;
            pointer-events: none; } }

/*961px*/
@media all and (min-width: 0px) and (max-width: 1280px) {
  .kensaku-box {
    padding: 20px !important;
    border: 3px solid #ddd;
    background: #eee; }
    .kensaku-box .col2 {
      border-bottom: none; }
    .kensaku-box .form-listbox {
      padding: 15px !important; }
    .kensaku-box .form-listbox {
      border-bottom: 1px solid #ddd !important; }
  .keiyaku-leftBox {
    width: 35%;
    float: left;
    position: relative;
    /*border: 1px solid #ddd;
	padding: 30px 30px 40px;
	box-sizing: border-box;*/ }
    .keiyaku-leftBox .form-contents {
      padding: 0;
      word-break: break-all; }
    .keiyaku-leftBox .form-ttl {
      height: 29px; }
  .keiyaku-rightBox {
    width: 61%;
    float: right;
    /*border: 1px solid #ddd;
	padding: 30px 30px 40px;
	box-sizing: border-box;*/ }
  .keiyaku-leftBox .form-listbox,
  .keiyaku-rightBox .form-listbox {
    height: 100px; }
  .keiyaku-leftBox .form-ttl,
  .keiyaku-rightBox .form-ttl {
    display: block;
    font-size: 1.3rem; }
  .keiyaku-leftBox .form-contents,
  .keiyaku-rightBox .form-contents {
    display: block;
    word-break: break-all; }
  .keiyaku-rightBox .tab_area {
    overflow: hidden;
    display: table;
    margin: 15px 0 0px 10px;
    border-spacing: 10px 0; }
    .keiyaku-rightBox .tab_area li {
      width: 110px;
      margin: 0 5px 0 0;
      padding: 14px 10px;
      cursor: pointer;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      font-weight: bold;
      font-size: 1.3rem;
      color: #000;
      background: #ccc;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      -webkit-border-top-left-radius: 10px;
      -webkit-border-top-right-radius: 10px;
      -moz-border-radius-topleft: 10px;
      -moz-border-radius-topright: 10px; }
  .tab_area li.select {
    /*アクティブタブの装飾*/
    color: #fff;
    background: #0077b7; }
  .btn-keiyakubox {
    margin: 0 0 50px;
    text-align: left; }
    .btn-keiyakubox .info {
      font-weight: bold;
      padding: 0 0 20px; }
    .btn-keiyakubox .inner {
      padding: 20px 0 10px;
      border-top: 1px solid #eee; }
      .btn-keiyakubox .inner .btn-box-inner {
        float: left;
        border-right: 1px solid #eee;
        padding: 10px 10px 5px 15px;
        margin: 0 10px 10px 0;
        background: #f0f2f8;
        border-radius: 6px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px; }
        .btn-keiyakubox .inner .btn-box-inner a {
          display: block;
          margin: 0 0 5px; }
      .btn-keiyakubox .inner button {
        display: inline-block;
        margin: 0 5px 10px; }
  /*table*/
  .table-list-box {
    width: 100%;
    overflow-x: scroll; }
    .table-list-box td {
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap; }
  #table-k01 td {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 40px;
    font-size: 1.1rem !important; }
  #table-k02 .table-scroll {
    width: 100%;
    overflow-x: scroll; }
  #table-k02 td {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap; }
  #table-k03 td {
    font-size: 1rem !important; }
  #table-k04 .none {
    display: none; } }

/*1280*/
.scroll-areaA {
  overflow-y: auto;
  height: 70px; }

.scroll-areaB {
  overflow-y: auto;
  height: 180px; }

@media screen and (max-width: 1480px) {
  /*

col
-----------------------------------*/
  .col2 {
    overflow: hidden;
    border-bottom: none; }
    .col2 .left,
    .col2 .right {
      width: 100% !important;
      border-bottom: 1px solid #eee; }
  .box-m {
    margin: 20px 0;
    clear: both; }
    .box-m .col2 .left {
      width: 100% !important;
      border-bottom: 1px solid #eee; }
    .box-m .col2 .right {
      width: 100% !important;
      border-bottom: 1px solid #eee; }
  .taishou-keiyaku-box {
    overflow: hidden;
    border-bottom: 1px solid #eee; }
    .taishou-keiyaku-box .left,
    .taishou-keiyaku-box .right {
      width: 100% !important;
      border-bottom: none !important; }
    .taishou-keiyaku-box .check-left {
      width: 50px;
      float: left;
      padding: 30px 0 0 20px; }
    .taishou-keiyaku-box .check-right {
      width: 82%;
      float: right; }
  .col3 {
    width: 100%;
    overflow: hidden;
    padding: 10px 0 20px; }
    .col3 .form-listboxB {
      width: 45%;
      float: left;
      margin: 0 5% 10px 0; }
    .col3 .form-listboxC {
      width: 45%;
      float: none;
      margin: 0 5% 10px 0; }
  .col4 {
    width: 100%; }
  .col4 .form-listboxB {
    width: 45%;
    float: left;
    margin: 0 5% 10px 0; }
  .col4 .form-listboxC {
    width: 45%;
    float: right;
    margin: 0 5% 0 0; }
  .form-listbox {
    padding: 15px 0 15px 0;
    border-bottom: 1px solid #eee;
    width: 100%;
    display: table; }
  .leftBoxA,
  .rightBoxA {
    width: 100%;
    float: none; } }

@media screen and (max-width: 1280px) {
  /*

col
-----------------------------------*/
  .col2 {
    overflow: hidden;
    border-bottom: none; }
    .col2 .left,
    .col2 .right {
      width: 100% !important;
      border-bottom: 1px solid #eee; }
  .taishou-keiyaku-box {
    overflow: hidden;
    border-bottom: 1px solid #eee; }
    .taishou-keiyaku-box .left,
    .taishou-keiyaku-box .right {
      width: 100% !important;
      border-bottom: none !important; }
    .taishou-keiyaku-box .check-left {
      width: 50px;
      float: left;
      padding: 30px 0 0 20px; }
    .taishou-keiyaku-box .check-right {
      width: 82%;
      float: right; }
  .col3 {
    width: 100%;
    overflow: hidden;
    padding: 10px 0 20px; }
    .col3 .form-listboxB {
      width: 100%;
      float: none;
      margin: 0 0 10px 0; }
    .col3 .form-listboxC {
      width: 100%;
      float: none;
      margin: 0 0 10px 0; }
  .col4 {
    width: 105%; }
  .col4 .form-listboxB {
    width: 100%;
    float: none;
    margin: 0 0 10px 0; }
  .col4 .form-listboxC {
    width: 100%;
    float: none;
    margin: 0 5% 10px 0; }
  .form-listbox {
    padding: 15px 0 15px 0;
    border-bottom: 1px solid #eee;
    width: 100%;
    display: table; }
  .leftBoxA,
  .rightBoxA {
    width: 100%;
    float: none; } }

#t-email {
  display: none; }

#m-sns,
#m-email,
#m-download {
  display: none; }

ul.list-syoumei li {
  margin: 0 0 5px 0;
  padding: 0 0 0 20px; }

ul.list-syoumei .icon-check {
  background: url("../img/icon_syoumei.svg") center left no-repeat;
  padding: 0 0 0 20px;
  background-size: 15px auto; }

ul.list-syoumei .icon-nocheck {
  background: url("../img/icon_syoumei_off.svg") center left no-repeat;
  padding: 0 0 0 20px;
  background-size: 15px auto; }

@media all and (min-width: 0px) and (max-width: 580px) {
  .box-primary {
    margin: 0 0 20px; }
    .box-primary h2 {
      font-size: 2rem !important; }
    .box-primary h3 {
      font-size: 1.6rem; }
    .box-primary h4 {
      font-size: 1.4rem !important;
      padding: 5px 10px; }
  .box-six {
    margin: 0 0 20px; }
  .box-inner {
    padding: 15px 15px 20px;
    overflow: hidden; }
    .box-inner p {
      font-size: 1.3rem; }
  .kakunin-box {
    max-width: 900px;
    margin: 50px 10px 100px; }
    .kakunin-box h2 {
      text-align: center;
      font-size: 2.4rem;
      padding: 0 0 20px;
      color: #0077b7; }
    .kakunin-box .keiyaku-cbox {
      width: 100%;
      padding: 30px 0; }
      .kakunin-box .keiyaku-cbox .innerA {
        border-bottom: 1px solid #ddd;
        padding: 0 0 20px;
        margin: 0 0 20px; }
      .kakunin-box .keiyaku-cbox .innerB {
        padding: 0 0 0px;
        text-align: center; }
      .kakunin-box .keiyaku-cbox .cbox {
        font-size: 1.3rem !important;
        font-weight: bold;
        padding: 5px 0; }
        .kakunin-box .keiyaku-cbox .cbox .checkbox-input + .checkbox-parts {
          display: inline-block;
          position: relative;
          padding-left: 0px;
          cursor: pointer;
          margin: 0; }
        .kakunin-box .keiyaku-cbox .cbox .checkbox-input + .checkbox-parts:before {
          content: "";
          display: inline-block;
          position: relative;
          top: 2px !important;
          bottom: 0;
          left: 0;
          width: 1em;
          height: 1em;
          margin: 0 4px 0 0;
          border: 1px solid #ddd;
          border-radius: 3px;
          background-repeat: no-repeat;
          background-size: contain;
          background: #f8f8f8; }
        .kakunin-box .keiyaku-cbox .cbox .checkbox-parts .checkbox-txt {
          margin: -23px 0 0 19px;
          display: block; }
        .kakunin-box .keiyaku-cbox .cbox .checkbox-input:checked + .checkbox-parts::after {
          content: "";
          display: block;
          position: absolute;
          top: 3px;
          left: 5px;
          width: 4px;
          height: 12px;
          -webkit-transform: rotate(40deg);
          -ms-transform: rotate(40deg);
          transform: rotate(40deg);
          border-bottom: 3px solid #0077b7;
          border-right: 3px solid #0077b7; }
    .kakunin-box .form-ttl {
      width: 110px;
      font-size: 1.2rem; }
    .kakunin-box .form-contents {
      font-size: 1.2rem;
      word-break: break-all; }
    .kakunin-box .sum {
      text-align: right;
      font-weight: bold;
      padding: 10px 0 0; }
      .kakunin-box .sum span {
        font-size: 1.8rem; }
    .kakunin-box .sum-box {
      border: none !important; }
      .kakunin-box .sum-box .form-ttl {
        padding: 20px 0 0 !important; }
  .attention-box {
    border: 3px solid #ccc;
    color: #888;
    padding: 20px;
    margin: 0 0 40px;
    background: #fff;
    position: relative;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    font-size: 1.3rem; }
    .attention-box .ttl-attention {
      font-weight: bold;
      padding: 0 0 10px; }
    .attention-box ul.list-attention li {
      list-style: disc;
      margin: 0 0 0 20px; }
    .attention-box p {
      padding: 0 0 10px; }
  .attention-boxB {
    border: 3px solid #ff0054;
    color: #ff0054;
    padding: 20px;
    margin: 0 0 30px;
    background: #fff;
    position: relative;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px; }
    .attention-boxB .ttl-attention {
      font-weight: bold;
      padding: 0 0 10px; }
    .attention-boxB ul.list-attention li {
      list-style: disc;
      margin: 0 0 0 20px; }
    .attention-boxB p {
      padding: 0 0 10px; }
  .cbox .checkbox-parts {
    padding-left: 25px;
    position: relative;
    margin-right: 0; }
  .cbox .checkbox-parts::before {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 0;
    width: 15px;
    height: 15px;
    border: 2px solid #eee;
    background: #f8f8f8; }
  .cbox .checkbox-input:checked + .checkbox-parts::after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 6px;
    width: 4px;
    height: 12px;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    border-bottom: 3px solid #0077b7;
    border-right: 3px solid #0077b7; }
  .cbox .checkbox-parts {
    display: block; }
  .btn-middle-blueB {
    border: 1px solid #0077b7;
    background: #0077b7;
    color: #fff !important;
    padding: 5px;
    display: block;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    width: 300px;
    font-size: 1.4rem;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    overflow: hidden;
    margin: 0 auto !important; }
  .btn-large-red {
    border: 1px solid #ff467a;
    background: #ff467a;
    color: #fff !important;
    padding: 5px;
    display: block;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    width: 100%;
    font-size: 1.4rem;
    margin: 0 auto;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    overflow: hidden; }
  .download-link {
    text-align: center !important; }
    .download-link a {
      display: block;
      text-align: center !important; }
  .btn-yakkan-download {
    border: 1px solid #0077b7;
    background: #0077b7;
    color: #fff !important;
    padding: 7px 0;
    display: block;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    width: 100%;
    font-size: 1.4rem;
    margin: 0 auto 0;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    overflow: hidden; }
  .leftBoxB {
    width: 100%;
    float: none;
    padding: 0 2% 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .rightBoxB {
    width: 100%;
    float: none;
    border-top: 1px solid #e6e2c9;
    border-left: none;
    padding: 0 2%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .form-listbox {
    padding: 0 0 30px;
    border-bottom: none !important;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block; }
  .form-ttl {
    width: 100% !important;
    border-bottom: 1px solid #eee;
    padding: 0 0 7px;
    margin: 0 0 10px;
    font-weight: bold;
    display: block;
    vertical-align: middle;
    font-size: 1.4rem !important; }
  .form-ttl-vt {
    width: 100%;
    border-bottom: 1px solid #eee;
    font-weight: bold;
    display: block;
    vertical-align: top;
    padding: 0 0 7px;
    margin: 0 0 10px;
    font-size: 1.4rem !important; }
  .form-contents {
    display: block;
    vertical-align: middle;
    word-break: break-all;
    font-size: 1.4rem !important; }
    .form-contents input[type="radio"], .form-contents input[type="check"] {
      vertical-align: -0.1em; }
  .list-ttlB {
    width: 100%;
    display: block;
    float: none;
    line-height: 2; }
  .nks-box {
    padding: 20px 20px 20px;
    margin: 0 0 30px;
    background: #eee !important; } }

/*580px*/
@media all and (min-width: 581px) and (max-width: 860px) {
  .keiyaku-cbox {
    width: 100%;
    padding: 30px 0; }
    .keiyaku-cbox .innerA {
      border-bottom: 1px solid #ddd;
      padding: 0 0 20px;
      margin: 0 0 20px; }
    .keiyaku-cbox .innerB {
      padding: 0 0 0px;
      text-align: center; }
    .keiyaku-cbox .cbox {
      font-size: 1.3rem !important;
      font-weight: bold;
      padding: 5px 0; }
      .keiyaku-cbox .cbox .checkbox-input + .checkbox-parts {
        display: inline-block;
        position: relative;
        padding-left: 0px;
        cursor: pointer;
        margin: 0; }
      .keiyaku-cbox .cbox .checkbox-input + .checkbox-parts:before {
        content: "";
        display: inline-block;
        position: relative;
        top: 2px !important;
        bottom: 0;
        left: 0;
        width: 1em;
        height: 1em;
        margin: 0 4px 0 0;
        border: 1px solid #ddd;
        border-radius: 3px;
        background-repeat: no-repeat;
        background-size: contain;
        background: #f8f8f8; }
      .keiyaku-cbox .cbox .checkbox-parts .checkbox-txt {
        margin: -33px 0 0 23px;
        display: block; }
      .keiyaku-cbox .cbox .checkbox-input:checked + .checkbox-parts::after {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: 6px;
        width: 4px;
        height: 12px;
        -webkit-transform: rotate(40deg);
        -ms-transform: rotate(40deg);
        transform: rotate(40deg);
        border-bottom: 3px solid #0077b7;
        border-right: 3px solid #0077b7; } }

.btn-attention {
  cursor: pointer;
  margin: 0 0 0 10px; }

.btn-attention-b {
  cursor: pointer;
  margin: 0 0 0 -0.5em; }

.btn-attention-c {
  cursor: pointer;
  margin: 0 0 0 -0.5em; }

.balloon {
  position: absolute;
  width: 300px;
  padding: 10px;
  color: #666;
  background: #fff;
  text-align: center;
  border-radius: 8px;
  border: 2px solid #0077b7;
  top: 100px;
  right: 30px;
  z-index: 5;
  display: none; }

.balloon-b {
  position: absolute;
  width: 350px;
  padding: 10px;
  color: #666;
  background: #fff;
  text-align: center;
  border-radius: 8px;
  border: 2px solid #0077b7;
  bottom: 36px;
  left: 200px;
  z-index: 5;
  display: none; }

.balloon-c {
  position: absolute;
  width: 300px;
  padding: 10px;
  color: #666;
  background: #fff;
  text-align: center;
  border-radius: 8px;
  border: 2px solid #0077b7;
  bottom: 36px;
  left: 420px;
  z-index: 5;
  display: none; }

.balloon-box {
  position: relative;
  margin: 0 70px 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.balloon-flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative; }

.balloon-boader {
  border: none; }

@media all and (min-width: 0px) and (max-width: 1480px) {
  .balloon-flexbox {
    display: block;
    position: relative; }
  .balloon-boader {
    border-bottom: 1px solid #eee; }
  .balloon {
    position: absolute;
    width: 300px;
    padding: 10px;
    color: #666;
    background: #fff;
    text-align: center;
    border-radius: 8px;
    border: 2px solid #0077b7;
    top: 150px;
    right: 30px;
    z-index: 5;
    display: none; }
  .balloon-b {
    position: absolute;
    width: 350px;
    padding: 10px;
    color: #666;
    background: #fff;
    text-align: center;
    border-radius: 8px;
    border: 2px solid #0077b7;
    bottom: 60px;
    left: 0;
    z-index: 5;
    display: none; }
  .balloon-c {
    position: absolute;
    width: 300px;
    padding: 10px;
    color: #666;
    background: #fff;
    text-align: center;
    border-radius: 8px;
    border: 2px solid #0077b7;
    bottom: 30px;
    left: 0;
    z-index: 5;
    display: none; } }

.qr-url {
  max-width: 450px;
  font-size: 1.1rem;
  margin: 20px auto 0; }

.box-agency {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 40px;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 20px; }

.box-takuken-number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 500px;
  margin-top: 30px;
  border-radius: 3px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.takuken-license-type {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-right: none;
  border-left: 1px solid #ddd;
  padding: 4px 6px;
  width: 71px;
  text-align: center; }

.select-prefecture {
  font: 1.4rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  padding: 4px 6px;
  height: 33px; }
  .select-prefecture:focus {
    outline: #ffe9ef;
    background: #ffe9ef; }
    .select-prefecture:focus::after {
      outline: #ffe9ef;
      background: #ffe9ef; }
  .select-prefecture[disabled] {
    filter: alpha(opacity=40);
    -moz-opacity: 0.4;
    opacity: 0.4; }
  .select-prefecture[readonly] {
    filter: alpha(opacity=40);
    -moz-opacity: 0.4;
    opacity: 0.4; }

.takuken-license-number {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 1px solid #ddd;
  padding: 4px 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.checkbox-menzei {
  margin-left: 30px; }

.checkbox-no {
  margin-left: 30px; }

.form-inbox-license-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .form-inbox-license-details-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }

.box-pay-method {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.pay-method {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.license-type {
  margin: -3px 0 0;
  border: 1px solid #ddd;
  border-left: none;
  background: #f8f8f8;
  text-align: center;
  height: 33px;
  padding: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  @media all and (min-width: 581px) and (max-width: 701px) {
    .license-type {
      border-left: 1px solid #ddd; } }
  @media all and (min-width: 0px) and (max-width: 531px) {
    .license-type {
      border-left: 1px solid #ddd; } }

.license-details {
  width: 50px;
  margin: -3px 0 0;
  border: 1px solid #ddd;
  border-left: none;
  text-align: center;
  line-height: 33px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff; }

.license-number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  margin: -3px 0 0;
  border: 1px solid #ddd;
  border-left: none;
  text-align: center;
  line-height: 33px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0px 3px 3px 0px;
  background: #fff; }

.license-type-takuken {
  border-left: 1px solid #ddd;
  text-align: center;
  height: 33px;
  padding: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.license-details-takuken {
  width: 50px;
  border-left: 1px solid #ddd;
  text-align: center;
  line-height: 33px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative; }
  .license-details-takuken-symbol-left {
    position: absolute;
    left: 5px; }
  .license-details-takuken-symbol-right {
    position: absolute;
    right: 5px; }

.license-number-takuken {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 241px;
  border-left: 1px solid #ddd;
  text-align: center;
  line-height: 33px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative; }
  .license-number-takuken-character-left {
    position: absolute;
    left: 5px; }
  .license-number-takuken-character-right {
    position: absolute;
    right: 5px; }

.form-establishment-date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #ddd;
  width: 238px;
  background: #f8f8f8;
  border-radius: 3px; }

.rental-contract-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.box-yakkan {
  background: #fff; }

.business-number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 3px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 31px;
  position: relative; }
  .business-number span {
    margin: 0 6px;
    position: absolute;
    left: 3px; }
  .business-number.is-disabled {
    opacity: 0.4;
    background: rgba(239, 239, 239, 0.3); }

@media screen and (min-width: 0px) and (max-width: 580px) {
  .onlyPc {
    display: none; }
  .box-takuken-number {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; } }

.txt-center {
  text-align: center; }

.box-takuken-number.is-disabled {
  opacity: 0.4;
  background: rgba(239, 239, 239, 0.3); }

.box-variable-expenses-inovice, .box-agent-code {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 10px 0; }

.span-rent-fee {
  line-height: 35px; }

.drag-and-drop-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid gray;
  width: 100%;
  height: 100px;
  cursor: pointer;
  border: 1px solid #ddd;
  padding: 4px 6px;
  background: #fff;
  border-radius: 3px; }

.drag-and-drop-attention {
  color: #aaa; }

.radio-input:disabled + label {
  cursor: not-allowed; }

.box-schedule-contract-day {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 30px; }

.box-deposit-day {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 30px; }

.display-modal:hover {
  background: #ffe9ef;
  cursor: pointer;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.invoice-date-locked {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 200px;
  background: #e8e8e8;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }
  .invoice-date-locked-attention {
    text-align: center;
    font-weight: bold; }

.renkei-sonota {
  width: 500px;
  height: 50px;
  outline: none;
  border: 1px solid #ddd;
  padding: 10px 30px 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: scroll;
  background: #f8f8f8;
  color: #676767;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  @media screen and (min-width: 581px) and (max-width: 768px) {
    .renkei-sonota {
      width: 300px; } }
  @media screen and (min-width: 0px) and (max-width: 580px) {
    .renkei-sonota {
      width: 90%;
      padding: 10px; }
      .renkei-sonota-box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; } }

.authorize-code {
  width: 330px; }
  @media all and (min-width: 0px) and (max-width: 580px) {
    .authorize-code {
      width: 300px; } }
